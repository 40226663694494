import { ColorPicker } from "antd";
import { presetPalettes } from "@ant-design/colors";
// import { saveColorBoxOpenData } from "../../../slices/commonMapDataSlice";
import { useDispatch } from "react-redux";

const customPreset = {
  Basic_Colors: [
    "#9FFCFD",
    "#0023F5",
    "#00129A",
    "#16417C",
    "#000C7B",
    "#FFFF00",
    "#F08650",
    "#817F26",
    "#7E84F7",
    "#3580BB",
    "#00023D",
    "#58135E",
    "#75F94D",
    "#EE8AF8",
    "#EA3680",
    "#7F82BB",
    "#75163F",
    "#377E47",
    "#367E7F",
    "#808080",
  ],
};

const CustomColorPicker = (props) => {
  const { value, onChange, children } = props;

  const genPresets = (presets = presetPalettes) =>
    Object.entries(presets).map(([label, colors]) => ({
      label: label.replace("_", " "),
      colors,
      defaultVisible: true,
    }));

  const presets = genPresets({
    ...customPreset,
  });

  const handleColorBoxOpen = (open) => {
    // dispatch(saveColorBoxOpenData({ colorBoxOpenData: open }));
  };

  return (
    <ColorPicker
      value={value}
      arrow={false}
      disabledAlpha
      placement="rightTop"
      presets={presets}
      onChange={onChange}
      onOpenChange={handleColorBoxOpen}
    >
      {children}
    </ColorPicker>
  );
};

export default CustomColorPicker;
