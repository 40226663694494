import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  stationList: [],
  chargingTypeColors: [],
  selectedChargingTypes: [],
  markerData: null,
  noDataMessage: "",
  chargingStationLoading: false,
};

export const chargingStationSlice = createSlice({
  name: "chargingStationData",
  initialState,
  reducers: {
    saveStationList: (state, action) => {
      const { stationList } = action.payload;
      return {
        ...state,
        stationList: stationList,
      };
    },
    saveChargingTypeColors: (state, action) => {
      const { chargingTypeColors } = action.payload;
      return {
        ...state,
        chargingTypeColors: chargingTypeColors,
      };
    },
    saveSelectedChargingTypes: (state, action) => {
      const { selectedChargingTypes } = action.payload;
      return {
        ...state,
        selectedChargingTypes: selectedChargingTypes,
      };
    },
    saveClickedMarkerData: (state, action) => {
      const { markerData } = action.payload;
      return {
        ...state,
        markerData: markerData,
      };
    },
    saveChargingStationNoDataMessage: (state, action) => {
      const { noDataMessage } = action.payload;
      return {
        ...state,
        noDataMessage: noDataMessage,
      };
    },
    saveChargingStationLoading: (state, action) => {
      const { chargingStationLoading } = action.payload;
      return {
        ...state,
        chargingStationLoading: chargingStationLoading,
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  saveStationList,
  saveChargingTypeColors,
  saveSelectedChargingTypes,
  saveClickedMarkerData,
  saveChargingStationNoDataMessage,
  saveChargingStationLoading,
} = chargingStationSlice.actions;

export default chargingStationSlice.reducer;
