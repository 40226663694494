/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// Redux
import {
  saveMapBounds,
  saveZoomLevel,
} from "../redux/slices/commonMapDataSlice";
// Internal Imports
import styles from "../assets/scss/map.module.scss";
import { appData } from "../utils/appData";
import searchIcon from "../assets/icons/search-icon.svg";
import jpText from "../utils/locales/jp.json";
import { debounceMarker } from "../utils/debounceMarker";
import { saveLoadingStateData } from "../redux/slices/commonMapDataSlice";
import AddressSearchModal from "./address-search/AddressSearchModal";
import Loader from "./common/loader/loader";
import useDrawStationMarkers from "../hooks/useDrawMarkerList";
import {
  removePrevListOfMarkers,
  removePrevListOfShelter,
  removePrevListOfShelterCircle,
  removePrevSelectedMarkerBg,
  removePrevSelectedShelterBg,
} from "../hooks/mapWidgetFunctions";
import useDrawShelterList from "../hooks/useDrawShelterList";
import { hazardURI } from "../utils/appData";

let { map } = window;
let mapOptions = {},
  mrk_widget,
  compass_widget;

export default function Map(props) {
  const { selectedMenu } = props;
  let imageLayer = null;
  const [mapScript] = useState(document.querySelector('script[src*="loader"]'));
  // Redux
  const dispatch = useDispatch();
  const { stationList, chargingTypeColors, chargingStationLoading } =
    useSelector((state) => state.chargingStationData);
  const {
    shelterList,
    shelterCircleRadius,
    shelterLoading,
    isCircleToggled,
    selectedShelterTypes,
  } = useSelector((state) => state.shelterData);
  const {
    isMonochrome,
    zoomLevel,
    loading,
    isCenterPositionToggled,
    isCompassDisplayToggled,
    selectedCityLoc,
    sideBarWidth,
  } = useSelector((state) => state.commonMapData);
  const { isHazardInfoSelected, selectedHazardInfo } = useSelector(
    (state) => state.hazardInformation
  );
  // hooks
  const { drawStationMarkers } = useDrawStationMarkers();
  const { drawShelterMarkers, drawShelterCircles } = useDrawShelterList();

  const defaultLatLng = appData.defaultLatLng;
  const [latLngBounds, setLatLngBounds] = useState(null);
  // Address Search
  const [addressModal, setAddressModal] = useState(false);
  const [showAddressSearchBtn, setShowAddressSearchBtn] = useState(false);

  const [isHomeMenuSelected, setIsHomeMenuSelected] = useState(
    selectedMenu.home
  );

  const initMap = () => {
    mapScript.onload = () => {
      window.ZisAuth.addEventListener("ready", async () => {
        mapOptions = await window.ZisAuth.getMapOption();
        mapOptions.center = new window.ZDC.LatLng(
          defaultLatLng.lat,
          defaultLatLng.lng
        );
        mapOptions.zoom = appData.defaultZoom;
        mapOptions.centerZoom = true;
        mapOptions.minZoom = 3;
        mapOptions.mouseWheelReverseZoom = true;
        mapOptions.zipsMapType = appData.mapTypes.colored;
        map = new window.ZDC.Map(
          document.getElementById("ZMap"),
          mapOptions,
          function () {
            map.addControl(new window.ZDC.ZoomButton("top-left"));
            map.addControl(new window.ZDC.ZoomButton("top-left"));
            // Draw Compass
            drawCompass();
            map.addControl(new window.ZDC.ScaleBar("bottom-left"));
            // Marker at center position
            drawCenterMarker();
            // Show Address Search button
            setShowAddressSearchBtn(true);

            dispatch(saveLoadingStateData({ loading: false }));
            getMapBounds();

            if (isHazardInfoSelected) {
              hazardImageLayer();
            } else {
              removeHazardImageLayer();
            }

            // Zoom Change
            map.addEventListener(
              "zoomstart",
              debounceMarker(function (event) {
                dispatch(
                  saveZoomLevel({
                    zoomLevel: Math.floor(event.newZoom),
                  })
                );
                getMapBounds();
              }, 300)
            );

            map.addEventListener("center_changed", function (event) {
              getMapBounds();
            });

            map.addEventListener("touchend", function (event) {
              getMapBounds();
            });
          },
          function () {
            // Failure callback
            console.log("--------Map Initialization Failed--------- ");
          }
        );
      });
    };
  };

  const drawCenterMarker = () => {
    mrk_widget = new window.ZDC.CenterMarker();
    map.addControl(mrk_widget);
  };

  const hazardImageLayer = () => {
    try {
      if (window.ZDC) {
        if (imageLayer === null) {
          imageLayer = new window.ZDC.ImageLayer(
            hazardURI[selectedHazardInfo.index],
            {
              opacity: 0.9,
              propagation: true,
            }
          );
          map.image_layer_widget = imageLayer;
          map.addWidget(imageLayer);
        }
      } else {
        console.error("ZDC is not defined");
      }
    } catch (error) {
      console.error("hazardImageLayer error", error);
    }
  };
  const removeHazardImageLayer = () => {
    if (map && map.image_layer_widget) {
      map.removeWidget(map.image_layer_widget);
      map.image_layer_widget = null;
    }
  };

  const drawCompass = () => {
    compass_widget = new window.ZDC.Compass("top-right");
    map.addControl(compass_widget);
  };

  const refreshMapSize = () => {
    if (map) {
      map.refreshSize();
    }
  };

  const reinitializeMap = () => {
    if (map) {
      const options = {
        zipsMapType: isMonochrome
          ? appData.mapTypes.monochrome
          : appData.mapTypes.colored,
      };

      const successFunc = () => {
        // Callback success
      };

      const failFunc = (error) => {
        console.error("Map re-initialization failed:", error);
      };

      map.reinitialize(options, successFunc, failFunc);
    }
  };

  // Get Map Bounds
  const getMapBounds = () => {
    if (map) {
      try {
        const bounds = map?.getLatLngBounds();
        setLatLngBounds(bounds);
        dispatch(saveMapBounds({ mapBounds: bounds }));
      } catch (error) {
        console.log("LatLng Bound Error: ", error);
      }
    }
  };

  // ##################### UserEffect #####################

  // Init Map
  useEffect(() => {
    if (mapScript) {
      initMap();
      dispatch(saveLoadingStateData({ loading: true }));
    }
  }, [mapScript]);

  // ##################### Charging Station #####################
  useEffect(() => {
    const timer = setTimeout(() => {
      if (map && stationList?.length > 0) {
        drawStationMarkers(map, latLngBounds, stationList, chargingTypeColors);
      } else {
        removePrevListOfMarkers(map);
        removePrevSelectedMarkerBg(map);
      }
    }, 300);
    return () => clearTimeout(timer);
    // marker click event works perfectly due to adding zoomLevel in dependency along with latLngBounds
  }, [zoomLevel, latLngBounds, stationList, chargingTypeColors]);

  // ##################### Shelter Visualization #####################
  useEffect(() => {
    const timer = setTimeout(() => {
      if (map && shelterList?.length > 0 && selectedShelterTypes?.length > 0) {
        drawShelterMarkers(
          map,
          latLngBounds,
          shelterList,
          selectedShelterTypes
        );
      } else {
        removePrevSelectedShelterBg(map);
        removePrevListOfShelter(map);
      }
    }, 300);
    return () => clearTimeout(timer);
    // marker click event works perfectly due to adding zoomLevel in dependency along with latLngBounds
  }, [zoomLevel, latLngBounds, shelterList]);
  useEffect(() => {
    const timer = setTimeout(() => {
      if (
        map &&
        shelterList?.length > 0 &&
        shelterCircleRadius > 0 &&
        isCircleToggled
      ) {
        drawShelterCircles(map, latLngBounds, shelterList, shelterCircleRadius);
      } else {
        removePrevListOfShelterCircle(map);
      }
    }, 300);
    return () => clearTimeout(timer);
    // marker click event works perfectly due to adding zoomLevel in dependency along with latLngBounds
  }, [
    zoomLevel,
    latLngBounds,
    shelterList,
    shelterCircleRadius,
    isCircleToggled,
  ]);

  // Re-center map based on City/Candidate selection from Home page
  useEffect(() => {
    if (map) {
      const { lat, lng } = selectedCityLoc;
      if (lat && lng) {
        let latLngs = new window.ZDC.LatLng(lat, lng);
        map?.setCenter(latLngs);
      }
    }
  }, [selectedCityLoc]);

  // Update Center Plus Marker based on isCenterPositionToggled update
  useEffect(() => {
    if (map && mrk_widget) {
      if (isCenterPositionToggled) {
        drawCenterMarker();
      } else {
        map.removeControl(mrk_widget);
      }
    }
  }, [mrk_widget, isCenterPositionToggled]);

  // Update Compass based on isCompassDisplayToggled update
  useEffect(() => {
    if (map && compass_widget) {
      if (isCompassDisplayToggled) {
        drawCompass();
      } else {
        map.removeControl(compass_widget);
      }
    }
  }, [compass_widget, isCompassDisplayToggled]);

  // Refresh Map Size when right pie-chart pane is shown/hidden from Info Comparison page and selected region group count is changed
  useEffect(() => {
    refreshMapSize();
    getMapBounds();
  }, [isHomeMenuSelected, sideBarWidth]);

  // Disable Zoom buttons while loading
  useEffect(() => {
    const zoomInIconElements = document.querySelector(
      ".zwgl-ctrl-icon.zwgl-ctrl-zoom-in"
    );
    const zoomOutIconElements = document.querySelector(
      ".zwgl-ctrl-icon.zwgl-ctrl-zoom-out"
    );

    if (zoomInIconElements) {
      if (loading || shelterLoading || chargingStationLoading) {
        zoomInIconElements.style.pointerEvents = "none";
      } else {
        zoomInIconElements.style.pointerEvents = "auto";
      }
    }

    if (zoomOutIconElements) {
      if (loading || shelterLoading || chargingStationLoading) {
        zoomOutIconElements.style.pointerEvents = "none";
      } else {
        zoomOutIconElements.style.pointerEvents = "auto";
      }
    }
  }, [loading, shelterLoading, chargingStationLoading]);

  useEffect(() => {
    reinitializeMap();
  }, [isMonochrome]);

  useEffect(() => {
    setIsHomeMenuSelected(selectedMenu.home);
  }, [selectedMenu.home]);

  useEffect(() => {
    if (isHazardInfoSelected && zoomLevel >= 12) {
      removeHazardImageLayer();

      hazardImageLayer();
    } else {
      removeHazardImageLayer();
    }
  }, [isHazardInfoSelected, selectedHazardInfo.index, zoomLevel]);

  return (
    <div
      className={`${styles.mapContainer} ${
        (loading || shelterLoading || chargingStationLoading) && "noClick"
      }`}
    >
      {(loading || shelterLoading || chargingStationLoading) && <Loader />}

      <div id="ZMap" className={`${styles.map}`} />

      {showAddressSearchBtn && (
        <div
          className={styles.addressBtnSec}
          onClick={() => setAddressModal(true)}
        >
          <img src={searchIcon} alt="search" />
          <button>{jpText.SEARCH_ADDRESS}</button>
        </div>
      )}

      {addressModal && (
        <AddressSearchModal isOpen={true} setIsOpen={setAddressModal} />
      )}
    </div>
  );
}
