const defaultLat = process.env.REACT_APP_DEFAULT_LAT;
const defaultLng = process.env.REACT_APP_DEFAULT_LNG;

export const appData = {
  defaultLatLng: {
    lat: defaultLat,
    lng: defaultLng,
  },
  defaultZoom: 14,
  navBarMenu: {
    home: "home",
    chargingStation: "chargingStation",
    shelterVisualization: "shelterVisualization",
    hazardInfo: "hazardInfo",
    facilityInfo: "facilityInfo",
    settings: "settings",
  },
  navMenuItems: {
    home: true,
    chargingStation: false,
    shelterVisualization: false,
    hazardInfo: false,
    facilityInfo: false,
    settings: false,
  },
  mapTypes: {
    colored: "kP8KjZdn",
    monochrome: "gZ6ujTVH",
  },
  kmToDegree: 0.008919, // 1 KM =  0.008919 Degree
  chargingStationMinZoom: 13,
  shelterMinZoom: 13,
};

export const defaultColors = [
  "#6c2df2",
  "#ea3680",
  "#c1e192",
  "#0dac8b",
  "#0ee526",
  "#88daf4",
  "#3e83a8",
  "#3946d1",
  "#01fbce",
  "#cc0607",
  "#421ea2",
  "#b94605",
  "#7959d7",
  "#e305f4",
  "#ed6d80",
  "#750105",
  "#fd5712",
  "#da852e",
  "#2d4512",
  "#fd9d92",
];

// Charging Station
export const chargingTypes = [
  { label: "普通充電 200V (コンセント型)", key: "3" }, // Normal charge 200V (Outlet type)
  { label: "普通充電 200V (ポール型)", key: "4,5" }, // Normal charge 200V (Pole type)
  { label: "普通充電 200V (形式不明)", key: "6" }, // Ordinary charge 200V (Format unknown)
  { label: "普通充電 100V (コンセント型)", key: "7" }, // Normal charge 100V (outlet type)
  { label: "急速充電", key: "1,2" }, // Fast Charging
];

export const shelterTypes = [
  "避難場所", // Evacuation site
  "避難所", // Evacuation shelter
  "ペット同伴避難所", // Pet-friendly evacuation shelter
  "福祉避難所", // Welfare evacuation shelter
  "近隣自治体避難所等", // Neighboring municipal evacuation shelter, etc.
];

export const shelterRadius = [
  { key: "500m", value: 500 },
  { key: "1km", value: 1000 },
  { key: "2km", value: 2000 },
  { key: "3km", value: 3000 },
];
export const defaultColorsHazard1 = [
  "#DC7ADC",
  "#F285C9",
  "#FF9191",
  "#FFB7B7",
  "#FFD8C0",
  "#F8E1A6",
  "#F7F5A9",
  "#FFFFB3",
];

export const defaultColorsHazard2 = [
  "#FB684C",
  "#FFED4C",
  "#C04C63",
  "#EDD86F",
  "#CA4C95",
  "#FFB74C",
];

export const hazardInfo = [
  "高潮",
  "津波",
  "土石流",
  "土砂災害警戒区域（急傾斜地の崩壊）",
  "土砂災害警戒区域（地すべり）",
];

export const hazardURI = [
  "https://disaportaldata.gsi.go.jp/raster/03_hightide_l2_shinsuishin_data/{z}/{x}/{y}.png",
  "https://disaportaldata.gsi.go.jp/raster/04_tsunami_newlegend_data/{z}/{x}/{y}.png",
  "https://disaportaldata.gsi.go.jp/raster/05_dosekiryukeikaikuiki/{z}/{x}/{y}.png",
  "https://disaportaldata.gsi.go.jp/raster/05_kyukeishakeikaikuiki/{z}/{x}/{y}.png",
  "https://disaportaldata.gsi.go.jp/raster/05_jisuberikeikaikuiki/{z}/{x}/{y}.png",
];

export const legendItems1 = [
  "20m以上の区域",
  "10m～20m未満の区域",
  "5m～10m未満の区域",
  "3m～5m未満の区域",
  "0.5m～3m未満の区域",
  "0.5m～1m未満の区域",
  "0.5m未満の区域",
  "0.3m未満の区域",
];

export const legendItems2 = [
  "特別警戒区域 急傾斜地の崩壊",
  "警戒区域 急傾斜地の崩壊",
  "特別警戒区域 土石流",
  "警戒区域 土石流",
  "警戒区域 地すべり",
];
