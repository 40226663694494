import { useDispatch } from "react-redux";
import {
  removePrevListOfShelter,
  removePrevListOfShelterCircle,
  removePrevSelectedShelterBg,
} from "./mapWidgetFunctions";
import {
  evacuationNeighbor,
  evacuationPetFriendly,
  evacuationShelter,
  evacuationSite,
  evacuationWelfare,
  getSelectedShelterBg,
} from "../utils/getCustomMarker";
import {
  saveClickedShelterDetails,
  saveIsShelterIconClicked,
  saveShelterLoading,
} from "../redux/slices/shelterVisualizationSlice";
import { shelterTypes } from "../utils/appData";

export default function useDrawShelterList() {
  const dispatch = useDispatch();

  const drawShelterMarkers = async (
    map,
    latLngBounds,
    shelterList,
    selectedShelterTypes
  ) => {
    await removePrevListOfShelter(map);
    let tempWgList = [],
      isShelterSelected = false;
    if (
      shelterList?.length > 0 &&
      latLngBounds &&
      selectedShelterTypes?.length > 0
    ) {
      shelterList.forEach((listItem, coordIndex) => {
        let imgData = null;

        if (
          listItem?.welfare_shelter &&
          selectedShelterTypes?.find((item) => item.value === shelterTypes[3])
        ) {
          imgData = evacuationWelfare();
        } else if (
          listItem?.with_pet &&
          selectedShelterTypes?.find((item) => item.value === shelterTypes[2])
        ) {
          imgData = evacuationPetFriendly();
        } else if (
          listItem?.shelter &&
          selectedShelterTypes?.find((item) => item.value === shelterTypes[1])
        ) {
          imgData = evacuationShelter();
        } else if (
          listItem?.out_of_cities_shelter &&
          selectedShelterTypes?.find((item) => item.value === shelterTypes[4])
        ) {
          imgData = evacuationNeighbor();
        } else if (
          listItem?.evacuation_site &&
          selectedShelterTypes?.find((item) => item.value === shelterTypes[0])
        ) {
          imgData = evacuationSite();
        }

        let latLngs = new window.ZDC.LatLng(
          listItem.latitude,
          listItem.longitude
        );

        // Draw Shelter Icon
        if (imgData) {
          // Marker count HTML source generation
          let shelter_wg = new window.ZDC.UserWidget(latLngs, {
            htmlSource: imgData,
            offset: new window.ZDC.Point(-14, -14),
            propagation: true,
          });

          tempWgList.push(shelter_wg);
          map.addWidget(shelter_wg);

          const shelterWidgetDiv = shelter_wg._element;
          if (shelterWidgetDiv) {
            shelterWidgetDiv.style.height = "28px";
            shelterWidgetDiv.style.zIndex = "9";
          }

          // Updated selected marker background if color is changed
          if (
            map?.shelter_selected_bg &&
            map?.shelter_selected_bg?._latlng?.lat === listItem.latitude &&
            map?.shelter_selected_bg?._latlng?.lng === listItem.longitude
          ) {
            isShelterSelected = true;
            drawSelectedMarkerBackground(map, latLngs, shelterList[coordIndex]);
          }
          // Set marker details to redux for the clicked marker
          shelter_wg.addEventListener("click", function (event) {
            drawSelectedMarkerBackground(map, latLngs, shelterList[coordIndex]);
            dispatch(saveIsShelterIconClicked({ isShelterIconClicked: true }));
          });

          // Set marker details to redux for the clicked marker
          // shelter_wg.addEventListener("click", function (event) {
          //   drawSelectedMarkerBackground(map, latLngs, shelterList[coordIndex]);
          // });
        }
      });

      if (!isShelterSelected) {
        removePrevSelectedShelterBg(map);
        dispatch(
          saveClickedShelterDetails({
            shelterDetails: null,
          })
        );
      }
      dispatch(saveShelterLoading({ shelterLoading: false }));
    } else {
      dispatch(saveShelterLoading({ shelterLoading: false }));
    }

    map.shelter_list_wg = tempWgList;
  };
  const drawSelectedMarkerBackground = (map, latLngs, shelterDetails) => {
    removePrevSelectedShelterBg(map);
    const selectMarkerBgImg = getSelectedShelterBg("#FF3838");
    let shelter_selected_bg = new window.ZDC.UserWidget(latLngs, {
      htmlSource: selectMarkerBgImg,
      offset: new window.ZDC.Point(-32, -32),
      propagation: true,
    });
    map.shelter_selected_bg = shelter_selected_bg;
    map.addWidget(shelter_selected_bg);

    const shelterSelectedBgWidgetDiv = shelter_selected_bg._element;
    if (shelterSelectedBgWidgetDiv) {
      shelterSelectedBgWidgetDiv.style.height = "64px";
      shelterSelectedBgWidgetDiv.style.clipPath = "circle(50%)";
      shelterSelectedBgWidgetDiv.style.zIndex = "10";
    }

    dispatch(
      saveClickedShelterDetails({
        shelterDetails: shelterDetails,
      })
    );
  };

  const drawShelterCircles = async (
    map,
    latLngBounds,
    shelterList,
    shelterCircleRadius
  ) => {
    await removePrevListOfShelterCircle(map);
    let circleWgList = [];
    if (shelterList?.length > 0 && latLngBounds) {
      shelterList.forEach((listItem, coordIndex) => {
        let latLngs = new window.ZDC.LatLng(
          listItem.latitude,
          listItem.longitude
        );

        // Draw circle
        var circle = new window.ZDC.Oval(
          latLngs,
          { x: shelterCircleRadius, y: shelterCircleRadius },
          {
            fillPattern: "none",
            stroke: "#CC0607",
            strokeWidth: "2",
            opacity: 1,
            propagation: true,
          }
        );
        circleWgList.push(circle);
        map.addWidget(circle);
      });
    }

    map.shelter_circle_wg = circleWgList;
  };

  return {
    drawShelterMarkers,
    drawShelterCircles,
  };
}
