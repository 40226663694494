import { appData, chargingTypes } from "../utils/appData";
import { useDispatch } from "react-redux";
import {
  saveChargingStationLoading,
  saveStationList,
} from "../redux/slices/chargingStationSlice";
import { apiFetch } from "../utils/fetchData";

export default function useChargingStationFunctions() {
  const authKey = process.env.REACT_APP_ZENRIN_API_KEY;
  const zenrinApi = process.env.REACT_APP_ZENRIN_API;
  const meshSizeInKm = process.env.REACT_APP_CHARGING_STATION_MESH_SIZE;
  const dispatch = useDispatch();

  const getChargingStationList = async (selectedItems, meshGrid) => {
    const selectedIndices = [],
      allItems = [];

    // Iterate through the selected items
    for (const selectedItem of selectedItems) {
      const selectedType = chargingTypes.filter(
        (type) => type.label === selectedItem.value
      );
      if (selectedType && selectedType?.length > 0) {
        selectedIndices.push(selectedType[0]?.key);
      }
    }
    // Convert the array of indices to a comma-separated string
    const formattedIndices = selectedIndices.join(",");
    const url = `${zenrinApi}/zips/general/search/charging_station`;
    const data = {
      zis_authtype: "ip",
      zis_authkey: authKey,
      charge_type: formattedIndices,
      limit: "0,1000",
    };

    if (meshGrid?.length > 0) {
      dispatch(saveChargingStationLoading({ chargingStationLoading: true }));
      for (const mesh of meshGrid) {
        try {
          const response = await fetchData(mesh, url, data);
          if (response?.result?.item?.length > 0) {
            let stationList = [];
            response.result.item.forEach((station) => {
              const {
                name,
                address,
                business_hours,
                charge_types,
                connector_num,
                billing_information,
                position,
              } = station;

              let stationData = {
                name,
                address,
                business_hours,
                charge_types,
                connector_num,
                billing_information,
                position,
              };
              stationList.push(stationData);
            });

            allItems.push(...stationList);
          }
        } catch (error) {
          // Handle errors (e.g., log them or skip this item)
          dispatch(
            saveChargingStationLoading({ chargingStationLoading: false })
          );
        }
      }
    } else {
      dispatch(saveChargingStationLoading({ chargingStationLoading: false }));
    }

    if (allItems && allItems?.length > 0) {
      dispatch(saveStationList({ stationList: allItems }));
    } else {
      dispatch(saveChargingStationLoading({ chargingStationLoading: false }));
      dispatch(saveStationList({ stationList: [] }));
    }
  };

  const fetchData = async (mesh, url, data) => {
    data.bbox = `${mesh[0].lng},${mesh[0].lat},${mesh[2].lng},${mesh[2].lat}`;
    try {
      const response = await apiFetch("GET", url, data);
      return response;
    } catch (error) {
      console.error("Error fetching data:", error);
      dispatch(saveChargingStationLoading({ chargingStationLoading: false }));
    }
  };

  const setChargingStationDetails = (setStationDetails, clickedMarkerData) => {
    const chargeTypes = clickedMarkerData?.charge_types?.split(";");
    const connectors = clickedMarkerData?.connector_num?.split(";");
    const businessHours = clickedMarkerData?.business_hours?.split(";");
    const billingInfo = clickedMarkerData?.billing_information?.split(";");
    const connectorNumStrings = [];
    const chargingTypeNames = [],
      chargingTypeList = [];

    const keyOrder = chargingTypes.flatMap((type) => type.key.split(","));
    const connectorNum = connectors.sort((a, b) => {
      return keyOrder.indexOf(a) - keyOrder.indexOf(b);
    });

    chargingTypes.forEach((typeItem, index) => {
      const chargerKeys = typeItem.key.split(",");
      chargeTypes.forEach((keyStr) => {
        if (chargerKeys.includes(keyStr)) {
          chargingTypeNames.push(typeItem.label);
        }
      });

      let isExist = false;
      chargerKeys.forEach((keyStr) => {
        if (chargeTypes.some((str) => str.includes(keyStr))) {
          isExist = true;
        }
      });
      if (isExist) {
        chargingTypeList.push(chargingTypes[index].label);
      }
    });

    connectorNum.forEach((connectorNumItem, index) => {
      connectorNumStrings.push(
        `${chargingTypeNames[index]}${connectorNumItem}台`
      );
    });

    setStationDetails({
      title: clickedMarkerData.name || "---", // Name
      items: [
        {
          label: "住所", // Address
          values: [clickedMarkerData.address],
        },
        {
          label: "充電方法", // Charging Method
          values: [chargingTypeList.join("/")],
        },
        {
          label: "営業時間", // Business Hours
          values: businessHours || [""],
        },
        {
          label: "充電スタンド数", // Charging Stand Count
          values: [connectorNumStrings.join("/")],
        },
        {
          label: "備考", // Remarks
          values: billingInfo || [""],
        },
      ],
    });
  };

  const getMeshGrid = (latLngBounds) => {
    let japanBounds = { north: 46, south: 24, east: 154, west: 122 };

    const latStep = meshSizeInKm * appData.kmToDegree;
    const lngStep = meshSizeInKm * appData.kmToDegree;

    const minLat =
      Math.floor((latLngBounds.southWest.lat - japanBounds.south) / latStep) *
        latStep +
      japanBounds.south;
    const minLng =
      Math.floor((latLngBounds.southWest.lng - japanBounds.west) / lngStep) *
        lngStep +
      japanBounds.west;

    const maxLat =
      Math.ceil((latLngBounds.northEast.lat - japanBounds.south) / latStep) *
        latStep +
      japanBounds.south;
    const maxLng =
      Math.ceil((latLngBounds.northEast.lng - japanBounds.west) / lngStep) *
        lngStep +
      japanBounds.west;

    const rectangleCoords = [];

    for (let latStart = minLat; latStart < maxLat; latStart += latStep) {
      for (let lngStart = minLng; lngStart < maxLng; lngStart += lngStep) {
        let temp = [
          new window.ZDC.LatLng(latStart, lngStart),
          new window.ZDC.LatLng(latStart, lngStart + lngStep),
          new window.ZDC.LatLng(latStart + latStep, lngStart + lngStep),
          new window.ZDC.LatLng(latStart + latStep, lngStart),
        ];
        rectangleCoords.push(temp);
      }
    }
    return rectangleCoords;
  };

  return {
    setChargingStationDetails,
    getMeshGrid,
    getChargingStationList,
  };
}
