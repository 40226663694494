/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import { Tooltip } from "antd";
import { useSelector } from "react-redux";

// Internal imports
import styles from "./checkbox-multiselect.module.scss";
import { isAllDisplayInfoChecked } from "../../../utils/isAllDisplayInfoChecked";
import CustomColorPicker from "../color-picker/CustomColorPicker";
import jpText from "../../../utils/locales/jp.json";

export default function CheckboxMultiSelect({
  items,
  colorCodes,
  colorChangeHandler,
  selectedItems,
  onCheckboxSelect,
  isDisabled,
  disableColorBox,
  hideSelectAll,
  icon,
  iconArray,
  iconPosition,
}) {
  const [itemList, setItemList] = useState([]);
  const [isAllChecked, setIsAllChecked] = useState(false);
  const { loading } = useSelector((state) => state.commonMapData);
  const { shelterLoading } = useSelector((state) => state.shelterData);
  const { chargingStationLoading } = useSelector(
    (state) => state.chargingStationData
  );

  const allCheckHandler = () => {
    setIsAllChecked(!isAllChecked);
    let checkAll = false;
    if (!isAllChecked) {
      checkAll = true;
    }

    let tempItems = [];
    itemList?.map((item) => {
      tempItems.push({ value: item.value, isChecked: checkAll });
    });
    const selectedItemsArray = tempItems?.filter((item) => item.isChecked);
    setItemList(tempItems);

    const updatedSelectedItems = [
      ...selectedItems?.filter(
        (item) => !itemList.find((tempItem) => tempItem.value === item.value)
      ),
      ...selectedItemsArray,
    ];
    onCheckboxSelect(updatedSelectedItems);
  };

  const checkboxClickHandler = (index) => {
    const tempItems = [...itemList];
    tempItems[index].isChecked = !tempItems[index].isChecked;
    setItemList(tempItems);

    let tempSelectedItems = [];
    tempItems?.forEach((item) => {
      if (item?.isChecked) {
        tempSelectedItems.push(item);
      }
    });
    onCheckboxSelect(tempSelectedItems);
  };

  useEffect(() => {
    if (items?.length > 0) {
      const tempList = items.map((item) => {
        const isChecked = selectedItems?.some(
          (selectedItem) => selectedItem.value === item
        );
        return { value: item, isChecked };
      });
      const isAllChecked = isAllDisplayInfoChecked(tempList);
      setIsAllChecked(isAllChecked);

      setItemList(tempList);
    } else {
      setItemList([]);
    }
  }, [items, selectedItems]);

  return (
    <div className={styles.container}>
      <div className={styles.innerContainer}>
        <>
          {itemList && itemList?.length > 0 && (
            <div className={`${styles.dropdownItemContainer}`}>
              <div className={styles.itemContainerInner}>
                {!hideSelectAll && (
                  <div
                    className={`${styles.dropdownItem} ${
                      isDisabled ? styles.disabled : ""
                    } ${isAllChecked && styles.dropdownItemSelected}`}
                    onClick={isDisabled ? () => {} : allCheckHandler}
                  >
                    <div className={styles.dropdownItemLP}>
                      <input
                        className={styles.checkbox}
                        type="checkbox"
                        checked={isAllChecked}
                        id={`all-select`}
                        name="all-select"
                        value="all-select"
                        onChange={() => {}}
                        disabled={
                          loading || shelterLoading || chargingStationLoading
                        }
                      />
                      <Tooltip
                        placement="top"
                        title={jpText.SELECT_ALL}
                        color="#FFFFFF"
                      >
                        <div className={styles.dropdownItemValue}>
                          {jpText.SELECT_ALL}
                        </div>
                      </Tooltip>
                    </div>
                  </div>
                )}

                {itemList.map((item, index) => (
                  <div
                    key={index}
                    className={`${styles.dropdownItem} ${
                      isDisabled ? styles.disabled : ""
                    } ${item.isChecked && styles.dropdownItemSelected}`}
                    onClick={
                      isDisabled ? () => {} : () => checkboxClickHandler(index)
                    }
                  >
                    <div className={styles.dropdownItemLP}>
                      <input
                        className={styles.inputCheckbox}
                        type="checkbox"
                        checked={item.isChecked}
                        id={`industry-${index}`}
                        name="industry"
                        value=""
                        onChange={() => {}}
                        disabled={
                          loading || shelterLoading || chargingStationLoading
                        }
                      />

                      {iconPosition === "start" && colorCodes?.length > 0 && (
                        <div
                          className={`${styles.colorSection} ${
                            disableColorBox ? styles.disabled : ""
                          }`}
                          onClick={(e) => e.stopPropagation()}
                        >
                          <CustomColorPicker
                            value={colorCodes[index]}
                            onChange={(color) =>
                              colorChangeHandler(color, index)
                            }
                          >
                            {icon ? (
                              <div
                                className={`${styles.colorSecIconContainer} ${
                                  item.isChecked &&
                                  styles.colorSecIconContainerSelected
                                }`}
                              >
                                {icon(colorCodes[index])}
                              </div>
                            ) : (
                              <div
                                className={styles.colorCircle}
                                style={{
                                  background: colorCodes[index],
                                }}
                              />
                            )}
                          </CustomColorPicker>
                        </div>
                      )}

                      {iconPosition === "start" &&
                        iconArray &&
                        iconArray[index] && (
                          <div
                            className={`${styles.colorSecStartIconContainer} ${
                              item.isChecked &&
                              styles.colorSecStartIconContainerSelected
                            }`}
                          >
                            <img
                              src={iconArray[index]}
                              alt={icon}
                              // onClick={(e) => e.stopPropagation()}
                            />
                          </div>
                        )}

                      <Tooltip
                        placement="top"
                        title={item.value}
                        color="#FFFFFF"
                      >
                        <div className={styles.dropdownItemValue}>
                          {item?.value}
                        </div>
                      </Tooltip>
                    </div>
                    {iconPosition === "end" && colorCodes?.length > 0 && (
                      <div
                        className={`${styles.colorSection} ${
                          disableColorBox ? styles.disabled : ""
                        }`}
                        onClick={(e) => e.stopPropagation()}
                      >
                        <CustomColorPicker
                          value={colorCodes[index]}
                          onChange={(color) => colorChangeHandler(color, index)}
                        >
                          {icon ? (
                            <div
                              className={`${styles.colorSecIconContainer} ${
                                item.isChecked &&
                                styles.colorSecIconContainerSelected
                              }`}
                            >
                              {icon(colorCodes[index])}
                            </div>
                          ) : (
                            <div
                              className={styles.colorCircle}
                              style={{
                                background: colorCodes[index],
                              }}
                            />
                          )}
                        </CustomColorPicker>
                      </div>
                    )}
                    {iconPosition === "end" &&
                      iconArray &&
                      iconArray[index] && (
                        <div
                          className={`${styles.colorSecIconContainer} ${
                            item.isChecked &&
                            styles.colorSecIconContainerSelected
                          }`}
                        >
                          <img
                            src={iconArray[index]}
                            alt={icon}
                            onClick={(e) => e.stopPropagation()}
                          />
                        </div>
                      )}
                  </div>
                ))}
              </div>
            </div>
          )}
        </>
      </div>
    </div>
  );
}
