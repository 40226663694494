/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
// Internal imports
import styles from "./shelter-visualization.module.scss";
import jpText from "../../../utils/locales/jp.json";
import SectionLayout from "../../common/section-layout/SectionLayout";
import SectionTitle from "../../common/left-pane-sec-title/SectionTitle";
import NoDataComponent from "../../common/no-data-message/NoDataComponent";
import noDataFoundImg from "../../../assets/images/no-data-found.svg";
import shelterVisualization from "../../../assets/icons/shelter-visualization.svg";
import shelterDetailsIcon from "../../../assets/icons/shelter-icons/shelter-details.svg";
import evacuationSiteIcon from "../../../assets/icons/shelter-icons/evacuation-site.svg";
import evacuationShelterIcon from "../../../assets/icons/shelter-icons/evacuation-shelter.svg";
import petFriendlyIcon from "../../../assets/icons/shelter-icons/shelter-pet-friendly.svg";
import welfareIcon from "../../../assets/icons/shelter-icons/shelter-welfare.svg";
import neighboringMunicipalIcon from "../../../assets/icons/shelter-icons/shelter-neighboring.svg";
import InfoDetails from "../../common/info-details/InfoDetails";
import ToggleSwitchButton from "../../common/toggle-switch-button/ToggleSwitchButton";
import Dropdown from "../../common/dropdown/DropdownRadius";
import Horizontal from "../../common/horizontal-line/Horizontal";
import CheckboxMultiSelect from "../../common/check-box/CheckboxMultiSelect";
import { useDispatch, useSelector } from "react-redux";
import {
  saveIsCircleToggled,
  saveSelectedShelterTypes,
  saveShelterCircleRadius,
  saveSelectedRadius,
} from "../../../redux/slices/shelterVisualizationSlice";
import { shelterRadius, shelterTypes } from "../../../utils/appData";

export default function ShelterVisualization() {
  const dispatch = useDispatch();
  const {
    selectedShelterTypes,
    isCircleToggled,
    shelterInfo,
    shelterSurroundingInfo,
    noDataMessage,
    selectedRadius,
    shelterLoading,
  } = useSelector((state) => state.shelterData);

  const [radiusList, setRadiusList] = useState([]);

  const checkboxSelectHandler = (list) => {
    dispatch(
      saveSelectedShelterTypes({
        selectedShelterTypes: list,
      })
    );
  };

  const handleToggle = () => {
    dispatch(saveIsCircleToggled({ isCircleToggled: !isCircleToggled }));
  };

  const handleRadiusChange = (radius) => {
    dispatch(saveSelectedRadius({ selectedRadius: radius }));
  };

  // Shelter Circle Radius
  useEffect(() => {
    if (shelterRadius?.length > 0) {
      const list = [];
      shelterRadius.forEach((item) => {
        list.push(item.key);
      });
      setRadiusList(list);
    } else {
      setRadiusList([]);
    }
  }, [shelterRadius]);
  useEffect(() => {
    const selectedRadiusItem = shelterRadius.find(
      (item) => item.key === selectedRadius
    );
    if (selectedRadiusItem) {
      dispatch(
        saveShelterCircleRadius({
          shelterCircleRadius: selectedRadiusItem.value,
        })
      );
    }
  }, [selectedRadius, isCircleToggled]);

  return (
    <div className={styles.container}>
      <SectionLayout>
        <SectionTitle
          title={jpText.EVACUATION_SHELTER_INFORMATION}
          icon={shelterVisualization}
          borderBottom={true}
        />

        <div className={styles.typeContainer}>
          <CheckboxMultiSelect
            items={shelterTypes}
            selectedItems={selectedShelterTypes}
            onCheckboxSelect={checkboxSelectHandler}
            hideSelectAll={true}
            iconArray={[
              evacuationSiteIcon,
              evacuationShelterIcon,
              petFriendlyIcon,
              welfareIcon,
              neighboringMunicipalIcon,
            ]}
            iconPosition="start"
            isDisabled={shelterLoading}
          />
        </div>
      </SectionLayout>

      <SectionLayout>
        <div className={styles.typeContainer}>
          <Dropdown
            title={jpText.RADIUS}
            options={radiusList}
            selectedOption={selectedRadius}
            setSelectedOption={handleRadiusChange}
            isDisabled={shelterLoading}
          />
          <div className={styles.lineSpacing}>
            <Horizontal className={styles.horizontal} />
          </div>
          <ToggleSwitchButton
            title={jpText.CIRCLE_DRAWING}
            isToggled={isCircleToggled}
            onToggle={handleToggle}
            isDisabled={shelterLoading}
          />
        </div>
      </SectionLayout>

      {shelterInfo === null && (
        <SectionLayout>
          <NoDataComponent
            image={noDataFoundImg}
            message={noDataMessage}
            className={styles.noDataMsgSec}
          />
        </SectionLayout>
      )}

      {shelterInfo && (
        <SectionLayout>
          <SectionTitle
            title={jpText.EVACUATION_SHELTER_DETAILS}
            icon={shelterDetailsIcon}
            borderBottom={true}
          />
          <div className={styles.shelterDetails}>
            <InfoDetails data={shelterInfo} />
          </div>
          <div className={styles.shelterDetails}>
            <InfoDetails data={shelterSurroundingInfo} />
          </div>
        </SectionLayout>
      )}
    </div>
  );
}
