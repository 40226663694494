/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// Internal imports
import styles from "./charging-station.module.scss";
import jpText from "../../../utils/locales/jp.json";
import SectionLayout from "../../common/section-layout/SectionLayout";
import SectionTitle from "../../common/left-pane-sec-title/SectionTitle";
import chargingStationIcon from "../../../assets/icons/charging-station.svg";
import chargingStationDetailsIcon from "../../../assets/icons/charging-station-details.svg";
import { chargingTypes } from "../../../utils/appData";
import { locationMarker } from "../../../utils/appIcons";
import noDataFoundImg from "../../../assets/images/no-data-found.svg";
import NoDataComponent from "../../common/no-data-message/NoDataComponent";
import InfoDetails from "../../common/info-details/InfoDetails";
import useChargingStationFunctions from "../../../hooks/useChargingStationFunctions";
import {
  saveChargingTypeColors,
  saveSelectedChargingTypes,
} from "../../../redux/slices/chargingStationSlice";
import CheckboxMultiSelect from "../../common/check-box/CheckboxMultiSelect";
import { Tooltip } from "antd";

export default function ChargingStation() {
  const dispatch = useDispatch();
  const {
    selectedChargingTypes,
    markerData,
    noDataMessage,
    chargingTypeColors,
  } = useSelector((state) => state.chargingStationData);
  const { loading } = useSelector((state) => state.commonMapData);
  const { shelterLoading } = useSelector((state) => state.shelterData);
  const { chargingStationLoading } = useSelector(
    (state) => state.chargingStationData
  );

  const { setChargingStationDetails } = useChargingStationFunctions();

  const [chargerTypes, setChargerTypes] = useState([]);

  const [chargerTypeColorList, setChargerTypeColorList] = useState(null);
  const [stationDetails, setStationDetails] = useState(null);

  const checkboxSelectHandler = (list) => {
    dispatch(
      saveSelectedChargingTypes({
        selectedChargingTypes: list,
      })
    );
  };

  const colorChangeHandler = (color, index) => {
    let list = JSON.parse(JSON.stringify(chargingTypeColors));
    const hexColor = color?.toHexString();
    list[index].colorCode = hexColor;
    dispatch(saveChargingTypeColors({ chargingTypeColors: list }));
  };

  // ##################### UserEffect #####################

  useEffect(() => {
    if (chargingTypes?.length > 0) {
      const items = [];
      chargingTypes.forEach((item) => {
        items.push(item.label);
      });
      setChargerTypes(items);
    } else {
      setChargerTypes([]);
    }
  }, [chargingTypes]);

  useEffect(() => {
    if (chargingTypeColors?.length > 0) {
      const list = [];
      chargingTypeColors.forEach((colorItem) => {
        list.push(colorItem.colorCode);
      });
      setChargerTypeColorList(list);
    }
  }, [chargingTypeColors]);

  // Set Station Details
  useEffect(() => {
    if (markerData && Object.keys(markerData)?.length > 0) {
      setChargingStationDetails(setStationDetails, markerData);
    } else {
      setStationDetails(null);
    }
  }, [markerData]);

  return (
    <div className={styles.container}>
      <SectionLayout>
        <SectionTitle
          title={jpText.CHARGING_STATION_INFORMATION}
          icon={chargingStationIcon}
          borderBottom={true}
        />

        <div className={styles.typeContainer}>
          <CheckboxMultiSelect
            items={chargerTypes}
            selectedItems={selectedChargingTypes}
            onCheckboxSelect={checkboxSelectHandler}
            hideSelectAll={true}
            icon={locationMarker}
            colorCodes={chargerTypeColorList}
            colorChangeHandler={colorChangeHandler}
            isDisabled={loading || shelterLoading || chargingStationLoading}
            iconPosition="end"
          />
          <div className={styles.multipleChargerLegend}>
            <Tooltip
              placement="top"
              title={jpText.MULTIPLE_NORMAL_OR_FAST_CHARGING}
              color="#FFFFFF"
            >
              <div className={styles.multipleChargerLegendText}>
                {jpText.MULTIPLE_NORMAL_OR_FAST_CHARGING}
              </div>
            </Tooltip>
            <div className={styles.multipleChargerLegendIconSec}>
              <div className={styles.multipleChargerLegendIconContainer}>
                {locationMarker("#808080")}
              </div>
            </div>
          </div>
        </div>
      </SectionLayout>

      {stationDetails === null && (
        <SectionLayout className={styles.noDataMsgContainer}>
          <NoDataComponent
            image={noDataFoundImg}
            message={noDataMessage}
            className={styles.noDataMsgSec}
          />
        </SectionLayout>
      )}

      {stationDetails && (
        <SectionLayout>
          <SectionTitle
            title={jpText.CHARGING_STATION_DETAILS}
            icon={chargingStationDetailsIcon}
            borderBottom={true}
          />
          <div className={styles.stationDetails}>
            <InfoDetails data={stationDetails} />
          </div>
        </SectionLayout>
      )}
    </div>
  );
}
