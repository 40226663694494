import { chargingTypes, shelterTypes } from "../utils/appData";
import { useDispatch } from "react-redux";
import { apiFetch } from "../utils/fetchData";
import {
  saveIsShelterIconClicked,
  saveShelterInfo,
  saveShelterList,
  saveShelterLoading,
  saveShelterSurroundingInfo,
} from "../redux/slices/shelterVisualizationSlice";
import jpText from "../utils/locales/jp.json";

export default function useShelterVisualizationFunctions() {
  const zenrinBjitApi = process.env.REACT_APP_ZENRIN_BJIT_API;
  const authKey = process.env.REACT_APP_ZENRIN_API_KEY;
  const zenrinApi = process.env.REACT_APP_ZENRIN_API;
  const dispatch = useDispatch();

  // Get Shelter List
  const getShelterList = async (selectedItems, mapBounds) => {
    dispatch(saveShelterLoading({ shelterLoading: true }));

    const data = {
      min_lat: mapBounds?.southWest?.lat,
      min_lng: mapBounds?.southWest?.lng,
      max_lat: mapBounds?.northEast?.lat,
      max_lng: mapBounds?.northEast?.lng,
    };

    // Iterate through the selected items
    for (const selectedItem of selectedItems) {
      if (selectedItem.value === shelterTypes[0]) {
        data.evacuation_site = true;
      } else if (selectedItem.value === shelterTypes[1]) {
        data.shelter = true;
      } else if (selectedItem.value === shelterTypes[2]) {
        data.with_pet = true;
      } else if (selectedItem.value === shelterTypes[3]) {
        data.welfare_shelter = true;
      } else if (selectedItem.value === shelterTypes[4]) {
        data.out_of_cities_shelter = true;
      }
    }

    const url = `${zenrinBjitApi}/shelters`;
    try {
      const response = await apiFetch("GET", url, data);

      if (response?.results?.length > 0) {
        dispatch(saveShelterList({ shelterList: response.results }));
      } else {
        dispatch(saveShelterLoading({ shelterLoading: false }));
        dispatch(saveShelterList({ shelterList: [] }));
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      dispatch(saveShelterLoading({ shelterLoading: false }));
      dispatch(saveShelterList({ shelterList: [] }));
    }
  };

  const setShelterDetails = async (
    clickedShelter,
    shelterCircleRadius,
    selectedChargingTypes
  ) => {
    if (clickedShelter?.no >= 0) {
      let totalStations = 0,
        totalConnectors = 0,
        shelterSurroundingInfo = {},
        shelterInfo = {};

      try {
        dispatch(saveShelterLoading({ shelterLoading: true }));

        // ############# Get Shelter Details #############
        const shelterDetailsUrl = `${zenrinBjitApi}/shelters/${clickedShelter.no}`;
        const data = {};
        if (Number(shelterCircleRadius) > 0) {
          data.radius = shelterCircleRadius;
        }
        const shelterResponse = await apiFetch("GET", shelterDetailsUrl, data);
        // return response;
        if (
          shelterResponse?.shelter &&
          Object.keys(shelterResponse.shelter)?.length > 0
        ) {
          const { shelter } = shelterResponse;
          const floodAreas = shelter?.fload_area?.split("\\n");
          const capacity =
            shelter?.capacity >= 0 ? `${shelter.capacity}人` : "---";
          const evStations =
            shelter?.ev_stations >= 0 ? `${shelter.ev_stations}台` : "---";
          const totalPopulation =
            shelter?.total_population >= 0
              ? `約${shelter.total_population}人`
              : "---";
          const phone = shelter.phone || "---";

          shelterInfo = {
            title: shelter.name || "---", // Name
            items: [
              {
                label: "住所", // Address
                values: [shelter.address],
              },
              {
                label: "電話番号", // Phone
                values: [phone],
              },
              {
                label: "浸水想定", // Flooding area
                values: floodAreas || [""],
              },
              {
                label: "定員", // Capacity
                values: [capacity],
              },
              {
                label: "充電スタンド", // Charging stand
                values: [evStations],
              },
            ],
          };

          shelterSurroundingInfo = {
            title: jpText.WHATS_NEARBY,
            items: [
              {
                label: "充電スタンド", // Charging stand
                values: ["---"],
              },
              {
                label: "人口", // Population
                values: [totalPopulation],
              },
            ],
          };

          dispatch(saveShelterInfo({ shelterInfo: shelterInfo }));
        } else {
          dispatch(saveShelterInfo({ shelterInfo: null }));
        }

        // ############# Get Surrounding Details #############
        const selectedStationIndices = [];
        for (const selectedItem of selectedChargingTypes) {
          const selectedType = chargingTypes.filter(
            (type) => type.label === selectedItem.value
          );
          if (selectedType && selectedType?.length > 0) {
            selectedStationIndices.push(selectedType[0]?.key);
          }
        }
        const formattedTypes = selectedStationIndices.join(",");

        const url = `${zenrinApi}/zips/general/search/charging_station`;
        const surroundingData = {
          zis_authtype: "ip",
          zis_authkey: authKey,
          charge_type: formattedTypes,
          limit: "0,1000",
          proximity: `${clickedShelter.longitude},${clickedShelter.latitude},${shelterCircleRadius}`,
        };
        const surroundingResponse = await apiFetch("GET", url, surroundingData);
        if (surroundingResponse?.result?.item?.length > 0) {
          totalStations = surroundingResponse.result.item.length;
          surroundingResponse.result.item.forEach((station) => {
            const connectorNumbers = station?.connector_num?.split(";");
            if (connectorNumbers?.length > 0) {
              connectorNumbers.forEach((connector) => {
                if (connector?.length > 0) {
                  totalConnectors = totalConnectors + Number(connector);
                }
              });
            }
          });
        }
        const chargingStands = `${totalStations}か所/基数: ${totalConnectors}`;

        if (shelterSurroundingInfo?.items[0]?.values) {
          shelterSurroundingInfo.items[0].values = [chargingStands];
          dispatch(
            saveShelterSurroundingInfo({
              shelterSurroundingInfo: shelterSurroundingInfo,
            })
          );
        } else {
          dispatch(
            saveShelterSurroundingInfo({
              shelterSurroundingInfo: null,
            })
          );
        }

        dispatch(saveShelterLoading({ shelterLoading: false }));
      } catch (error) {
        console.error("Error fetching data:", error);
        dispatch(saveShelterLoading({ shelterLoading: false }));
        dispatch(saveShelterInfo({ shelterInfo: null }));
        dispatch(
          saveShelterSurroundingInfo({
            shelterSurroundingInfo: null,
          })
        );
      }
      dispatch(saveIsShelterIconClicked({ isShelterIconClicked: false }));
    }
  };

  return {
    getShelterList,
    setShelterDetails,
  };
}
