import React, { useEffect, useState } from "react";
import { Tooltip } from "antd";
import { useSelector } from "react-redux";

import styles from "./dropdown-radio.module.scss";

export default function DropdownRadioSelect({
  items,
  selectedItem,
  onRadioSelect,
  isDisabled,
}) {
  const [itemList, setItemList] = useState([]);
  const { loading } = useSelector((state) => state.commonMapData);
  const { shelterLoading } = useSelector((state) => state.shelterData);
  const { chargingStationLoading } = useSelector(
    (state) => state.chargingStationData
  );

  const radioClickHandler = (index) => {
    const tempItems = itemList.map((item, idx) => ({
      ...item,
      isChecked: idx === index,
    }));
    setItemList(tempItems);

    const selectedItem = tempItems[index];
    onRadioSelect(selectedItem);
  };

  useEffect(() => {
    if (items?.length > 0) {
      const tempList = items.map((item) => {
        const isChecked = selectedItem?.value === item;
        return { index: items.indexOf(item), value: item, isChecked };
      });
      setItemList(tempList);
    } else {
      setItemList([]);
    }
  }, [items, selectedItem]);

  return (
    <div className={styles.container}>
      <div className={styles.innerContainer}>
        {itemList && itemList.length > 0 && (
          <div className={styles.dropdownItemContainer}>
            <div className={styles.itemContainerInner}>
              {itemList.map((item, index) => (
                <div
                  key={index}
                  className={`${styles.dropdownItem} ${
                    isDisabled ? styles.disabled : ""
                  } ${item.isChecked && styles.dropdownItemSelected}`}
                  onClick={
                    isDisabled ? () => {} : () => radioClickHandler(index)
                  }
                >
                  <div className={styles.dropdownItemLP}>
                    <input
                      className={styles.inputRadio}
                      type="radio"
                      checked={item.isChecked}
                      id={`radio-${index}`}
                      name="radio-group"
                      value={item.value}
                      onChange={() => {}}
                      disabled={
                        loading || shelterLoading || chargingStationLoading
                      }
                    />
                    <Tooltip placement="top" title={item.value} color="#FFFFFF">
                      <div className={styles.dropdownItemValue}>
                        {item?.value}
                      </div>
                    </Tooltip>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
