import { createSlice } from "@reduxjs/toolkit";
import { appData } from "../../utils/appData";

const initialState = {
  selectedCityLoc: appData.defaultLatLng,
  colorBoxOpenData: false,
  selectedGraph: {
    graph1: true,
    graph2: false,
    graph3: false,
  },
  isMonochrome: false,
  mapBounds: null,
  zoomLevel: appData.defaultZoom,
  loading: true,
  isCompassDisplayToggled: true,
  isCenterPositionToggled: true,
  sideBarWidth: 0,
};

export const commonMapDataSlice = createSlice({
  name: "commonMapData",
  initialState,
  reducers: {
    saveSelectedCityLoc: (state, action) => {
      const { selectedCityLoc } = action.payload;
      return {
        ...state,
        selectedCityLoc: selectedCityLoc,
      };
    },
    saveColorBoxOpenData: (state, action) => {
      const { colorBoxOpenData } = action.payload;
      return {
        ...state,
        colorBoxOpenData: colorBoxOpenData,
      };
    },

    saveMapBounds: (state, action) => {
      const { mapBounds } = action.payload;
      return {
        ...state,
        mapBounds: mapBounds,
      };
    },
    saveZoomLevel: (state, action) => {
      const { zoomLevel } = action.payload;
      return {
        ...state,
        zoomLevel: zoomLevel,
      };
    },
    saveSelectedMapType: (state) => {
      state.isMonochrome = !state.isMonochrome;
    },
    saveLoadingStateData: (state, action) => {
      const { loading } = action.payload;
      return {
        ...state,
        loading: loading,
      };
    },
    saveSideBarWidth: (state, action) => {
      const { sideBarWidth } = action.payload;
      return {
        ...state,
        sideBarWidth: sideBarWidth,
      };
    },
    toggleCompassDisplay: (state) => {
      state.isCompassDisplayToggled = !state.isCompassDisplayToggled;
    },
    toggleCenterPosition: (state) => {
      state.isCenterPositionToggled = !state.isCenterPositionToggled;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  saveSelectedCityLoc,
  saveColorBoxOpenData,
  saveMapBounds,
  saveZoomLevel,
  saveSelectedMapType,
  saveLoadingStateData,
  saveSideBarWidth,
  toggleCompassDisplay,
  toggleCenterPosition,
} = commonMapDataSlice.actions;

export default commonMapDataSlice.reducer;
