/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
// Redux
import { useDispatch, useSelector } from "react-redux";

// Internal Imports
import LeftNavBar from "../components/LeftNavBar";
import RightPane from "../components/RightPane";
import styles from "../assets/scss/home.module.scss";
import { appData, chargingTypes, defaultColors } from "../utils/appData";
import Settings from "../components/core-components/settings/Settings";
import ChargingStation from "../components/core-components/charging-station/ChargingStation";
import HazardInfo from "../components/core-components/hazard-info/HazardInfo";
import ShelterVisualization from "../components/core-components/shelter-visualization/ShelterVisualization";
import FacilityInfo from "../components/core-components/facility-info/FacilityInfo";
import useChargingStationFunctions from "../hooks/useChargingStationFunctions";
import {
  saveChargingTypeColors,
  saveClickedMarkerData,
  saveChargingStationNoDataMessage,
  saveStationList,
} from "../redux/slices/chargingStationSlice";
import jpText from "../utils/locales/jp.json";
import {
  saveClickedShelterDetails,
  saveShelterInfo,
  saveShelterList,
  saveShelterNoDataMessage,
  saveShelterSurroundingInfo,
} from "../redux/slices/shelterVisualizationSlice";
import useShelterVisualizationFunctions from "../hooks/useShelterVisualizationFunctions";

export default function Home() {
  // Redux
  const dispatch = useDispatch();
  const { loading, mapBounds, zoomLevel } = useSelector(
    (state) => state.commonMapData
  );
  const { selectedChargingTypes, chargingStationLoading } = useSelector(
    (state) => state.chargingStationData
  );
  const {
    selectedShelterTypes,
    shelterLoading,
    shelterDetails,
    shelterCircleRadius,
    isShelterIconClicked,
  } = useSelector((state) => state.shelterData);
  // hooks
  const { getMeshGrid, getChargingStationList } = useChargingStationFunctions();
  const { getShelterList, setShelterDetails } =
    useShelterVisualizationFunctions();

  const [selectedMenu, setSelectedMenu] = useState(appData.navMenuItems);
  const [sideBarElement, setSideBarElement] = useState(null);

  // Stations
  const [viewPortMeshGrid, setViewPortMeshGrid] = useState([]);

  // ######################### Charging Stations #########################

  // Get viewport mesh grid for mapBounds changes
  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     if (mapBounds && zoomLevel >= appData.chargingStationMinZoom) {
  //       const meshGrid = getMeshGrid(mapBounds);
  //       if (meshGrid?.length > 0) {
  //         setViewPortMeshGrid(meshGrid);
  //       } else {
  //         setViewPortMeshGrid([]);
  //       }
  //     } else {
  //       setViewPortMeshGrid([]);
  //     }
  //   }, 500);

  //   return () => clearTimeout(timer);
  // }, [mapBounds?.northEast?.lng]);
  useEffect(() => {
    if (mapBounds && zoomLevel >= appData.chargingStationMinZoom) {
      const meshGrid = getMeshGrid(mapBounds);
      if (meshGrid?.length > 0) {
        const timer = setTimeout(() => {
          setViewPortMeshGrid(meshGrid);
        }, 500);

        return () => clearTimeout(timer);
      } else {
        setViewPortMeshGrid([]);
      }
    } else {
      setViewPortMeshGrid([]);
    }
  }, [mapBounds?.northEast?.lng]);

  // Get new markers for charging station type change
  useEffect(() => {
    if (selectedChargingTypes?.length <= 0) {
      dispatch(
        saveChargingStationNoDataMessage({
          noDataMessage: jpText.SELECT_CHARGING_STATION_TEXT,
        })
      );
      dispatch(saveStationList({ stationList: [] }));
      dispatch(
        saveClickedMarkerData({
          markerData: null,
        })
      );
    } else if (
      selectedChargingTypes?.length > 0 &&
      viewPortMeshGrid?.length > 0
    ) {
      dispatch(
        saveChargingStationNoDataMessage({
          noDataMessage: jpText.CLICK_ON_CHARGING_STATION_ICON,
        })
      );
      getChargingStationList(selectedChargingTypes, viewPortMeshGrid);
    } else {
      dispatch(saveStationList({ stationList: [] }));
      dispatch(
        saveClickedMarkerData({
          markerData: null,
        })
      );
    }
  }, [selectedChargingTypes, viewPortMeshGrid]);

  // update map marker color for checkbox color change
  useEffect(() => {
    const list = [];
    chargingTypes?.forEach((type, index) => {
      list.push({ ...type, colorCode: defaultColors[index] });
    });
    dispatch(saveChargingTypeColors({ chargingTypeColors: list }));
  }, []);

  // ######################### Shelter Visualization #########################

  useEffect(() => {
    if (selectedShelterTypes?.length <= 0) {
      dispatch(
        saveShelterNoDataMessage({
          noDataMessage: jpText.SELECT_EVACUATION_SHELTER_TEXT,
        })
      );
      dispatch(saveShelterList({ shelterList: [] }));
      dispatch(
        saveClickedShelterDetails({
          shelterDetails: null,
        })
      );
    } else if (
      selectedShelterTypes?.length > 0 &&
      mapBounds &&
      Object.keys(mapBounds)?.length > 0 &&
      zoomLevel >= appData.shelterMinZoom
    ) {
      const timer = setTimeout(() => {
        getShelterList(selectedShelterTypes, mapBounds);
        dispatch(
          saveShelterNoDataMessage({
            noDataMessage: jpText.CLICK_ON_SHELTER_EVACUATION_ICON,
          })
        );
      }, 500);

      return () => clearTimeout(timer);
    } else {
      dispatch(saveShelterList({ stationList: [] }));
      dispatch(
        saveClickedShelterDetails({
          markerData: null,
        })
      );
    }
  }, [selectedShelterTypes, mapBounds?.northEast?.lng, zoomLevel]);
  // Set Station Details
  useEffect(() => {
    if (shelterDetails && Object.keys(shelterDetails)?.length > 0) {
      if (isShelterIconClicked) {
        setShelterDetails(
          shelterDetails,
          shelterCircleRadius,
          selectedChargingTypes
        );
      }
    } else {
      dispatch(saveShelterInfo({ shelterInfo: null }));
      dispatch(
        saveShelterSurroundingInfo({
          shelterSurroundingInfo: null,
        })
      );
    }
  }, [
    shelterDetails,
    shelterCircleRadius,
    selectedChargingTypes,
    isShelterIconClicked,
  ]);

  // ######################### Common/Home #########################

  useEffect(() => {
    if (selectedMenu?.settings) {
      setSideBarElement(<Settings />);
    } else if (selectedMenu?.chargingStation) {
      setSideBarElement(<ChargingStation />);
    } else if (selectedMenu?.hazardInfo) {
      setSideBarElement(<HazardInfo />);
    } else if (selectedMenu?.shelterVisualization) {
      setSideBarElement(<ShelterVisualization />);
    } else if (selectedMenu?.facilityInfo) {
      setSideBarElement(<FacilityInfo />);
      // Home
    } else {
      setSideBarElement(null);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMenu]);

  return (
    <div className={styles.container}>
      <LeftNavBar
        selectedMenu={selectedMenu}
        setSelectedMenu={setSelectedMenu}
        className={
          (loading || shelterLoading || chargingStationLoading) && "noClick"
        }
      />
      <RightPane selectedMenu={selectedMenu}>{sideBarElement}</RightPane>
    </div>
  );
}
