import React from "react";

// Internal Imports
import styles from "../assets/scss/left-navbar.module.scss";
import { appData } from "../utils/appData";
import {
  navChargingStationIcon,
  navFacilityIcon,
  navHazardIcon,
  navHomeIcon,
  navSettingIcon,
  navShelterIcon,
} from "../utils/appIcons";

export default function LeftNavBar(props) {
  const { selectedMenu, setSelectedMenu, className } = props;

  const menuClickHandler = (menu) => {
    const items = { ...appData.navMenuItems };
    Object.keys(items).forEach((item) => {
      if (item === menu) {
        items[item] = true;
      } else {
        items[item] = false;
      }
    });
    setSelectedMenu(items);
  };

  const navButtonComponent = (flag, menuName, iconFunction) => {
    return (
      <div className={styles.menuGroup}>
        <div
          className={flag ? styles.selectedMenu : styles.selectedMenuWhite}
          onClick={() => menuClickHandler(menuName)}
        >
          {flag ? iconFunction() : iconFunction("#292D32")}
        </div>
      </div>
    );
  };

  return (
    <div className={`${styles.container} ${className}`}>
      {/* Home */}
      {navButtonComponent(
        selectedMenu.home,
        appData.navBarMenu.home,
        navHomeIcon
      )}
      {/* Charging Station */}
      {navButtonComponent(
        selectedMenu.chargingStation,
        appData.navBarMenu.chargingStation,
        navChargingStationIcon
      )}
      {/* Shelter Visualization */}
      {navButtonComponent(
        selectedMenu.shelterVisualization,
        appData.navBarMenu.shelterVisualization,
        navShelterIcon
      )}
      {/* Hazard Info */}
      {navButtonComponent(
        selectedMenu.hazardInfo,
        appData.navBarMenu.hazardInfo,
        navHazardIcon
      )}
      {/* Facility Info */}
      {/* {navButtonComponent(
        selectedMenu.facilityInfo,
        appData.navBarMenu.facilityInfo,
        navFacilityIcon
      )} */}
      {/* settings */}
      {/* {navButtonComponent(
        selectedMenu.settings,
        appData.navBarMenu.settings,
        navSettingIcon
      )} */}
    </div>
  );
}
