import React from "react";

import styles from "./toggle-switch-button.module.scss";

const ToggleSwitchButton = (props) => {
  const { title, onToggle, isToggled, isDisabled } = props;

  return (
    <div className={styles.toggleSwitchButton}>
      {title && <div className={styles.title}>{title}</div>}
      <div
        className={`${styles.toggleArea} ${isDisabled ? styles.disabled : ""} ${
          isDisabled && isToggled ? styles.disabledFaded : ""
        } ${isToggled ? styles.toggled : ""}`}
        onClick={onToggle}
      >
        <div className={styles.roundButton}></div>
      </div>
    </div>
  );
};

export default ToggleSwitchButton;
