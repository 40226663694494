export const getCustomMarker = (color) => {
  return `<svg width="20" height="28" viewBox="0 0 20 28" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M10 0C5.5808 0 0 3.63756 0 8.1433C0 15.9341 10 26.0945 10 26.0945C10 26.0945 20 15.9325 20 8.1433C20 3.63919 14.4192 0 10 0ZM10 13.649C8.85426 13.649 7.75546 13.1848 6.9453 12.3585C6.13514 11.5322 5.68 10.4114 5.68 9.24284C5.68 8.07425 6.13514 6.95352 6.9453 6.1272C7.75546 5.30087 8.85426 4.83665 10 4.83665C11.1457 4.83665 12.2445 5.30087 13.0547 6.1272C13.8649 6.95352 14.32 8.07425 14.32 9.24284C14.32 10.4114 13.8649 11.5322 13.0547 12.3585C12.2445 13.1848 11.1457 13.649 10 13.649Z" fill="${color}"/>
  <ellipse opacity="0.5" cx="10" cy="26" rx="4" ry="2" fill="${color}"/>
  </svg>`;
};

export const getCustomMarkerSelectedBg = (color) => {
  return `<svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
  <circle opacity="0.3" cx="14.5" cy="14.5" r="14" fill="${color}"/>
  <circle opacity="0.2" cx="14.5" cy="14.5" r="10.8889" fill="${color}"/>
  </svg>`;
};

export const evacuationShelter = () => {
  return `<svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M25.6668 8.16668V19.8333C25.6668 23.3333 23.7224 25.6667 19.1853 25.6667H8.81498C4.27794 25.6667 2.3335 23.3333 2.3335 19.8333V8.16668C2.3335 4.66668 4.27794 2.33334 8.81498 2.33334H19.1853C23.7224 2.33334 25.6668 4.66668 25.6668 8.16668Z" fill="white" stroke="#1C8C42" stroke-width="1.75" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M7 21.1H11.8L10.15 19.45V8.1H17.85V16C18.85 16.04 19.2667 17.1833 19.35 17.75H17.85V19.45L19.5 21.1H21V7H7V21.1Z" fill="#292D32"/>
  <path d="M13 21.1L11.35 19.45H11.8C12.44 19.45 12.8667 19.6167 13 19.7L14.4 21.1H13Z" fill="#292D32"/>
  <circle cx="12.735" cy="9.63769" r="1.03772" fill="#292D32"/>
  <path d="M11.2609 12.697H9.54125C9.4319 12.7426 9.24083 12.8382 9.20687 13.0792C9.15705 13.4329 9.39794 13.5569 9.54125 13.6046H11.2609C11.6812 13.6046 11.9137 13.3817 11.9774 13.2703L12.4073 12.697C12.6009 12.4389 12.6939 12.4423 12.7417 12.5537C12.8213 12.713 13.0283 13.1652 13.2194 13.7002C13.4104 14.2352 13.299 14.6237 13.2194 14.7511L11.1653 19.0979H11.8819C12.3786 19.1361 12.6621 18.6362 12.7417 18.3814C13.2353 17.3464 14.2511 15.2096 14.3658 14.9421C14.5186 14.6746 14.6205 14.767 14.6524 14.8466V16.9006C14.6906 17.321 15.0505 17.4579 15.2256 17.4738H18.9515C18.7604 16.786 18.3942 16.4866 18.235 16.4229H15.751C15.767 16.2319 15.7893 15.6491 15.751 14.8466C15.7128 14.0441 15.4485 13.3976 15.3211 13.1747L14.7479 11.6939H15.7988C15.974 12.0124 16.3816 12.7353 16.6109 13.0792C16.884 13.4136 17.1228 13.318 17.3274 13.1747C17.5567 12.9837 17.3911 12.5856 17.2796 12.4104C17.1363 12.1557 16.8019 11.5602 16.6109 11.2162C16.3434 10.8341 16.0217 10.7704 15.8943 10.7863H12.8372C12.5697 10.7863 12.3457 11.2141 12.2162 11.4073L11.2609 12.697Z" fill="#292D32"/>
  </svg>
`;
};

export const evacuationSite = () => {
  return `<svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M25.6668 8.16666V19.8333C25.6668 23.3333 23.7224 25.6667 19.1853 25.6667H8.81498C4.27794 25.6667 2.3335 23.3333 2.3335 19.8333V8.16666C2.3335 4.66666 4.27794 2.33333 8.81498 2.33333H19.1853C23.7224 2.33333 25.6668 4.66666 25.6668 8.16666Z" fill="white" stroke="#1C8C42" stroke-width="1.75" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M12.5464 21.2851C16.2539 21.2851 19.2593 19.8906 19.2593 18.1703C19.2593 17.0828 18.0582 16.1255 16.238 15.5684C16.2101 15.6275 16.1762 15.7002 16.1374 15.7831C15.9402 16.2055 15.6183 16.8947 15.339 17.4185C15.0383 18.0199 14.3544 18.0987 14.0501 18.0629H12.7075L14.0394 15.1328C13.5592 15.0822 13.0596 15.0555 12.5464 15.0555C8.83897 15.0555 5.8335 16.4501 5.8335 18.1703C5.8335 19.8906 8.83897 21.2851 12.5464 21.2851Z" fill="#292D32"/>
  <circle cx="15.1779" cy="6.99999" r="1.16667" fill="#292D32"/>
  <path d="M13.5209 10.4395H11.5875C11.4646 10.4907 11.2498 10.5981 11.2116 10.8691C11.1556 11.2668 11.4264 11.4062 11.5875 11.4599H13.5209C13.9934 11.4599 14.2548 11.2092 14.3264 11.0839L14.8097 10.4395C15.0274 10.1493 15.132 10.1531 15.1857 10.2784C15.2752 10.4574 15.5079 10.9658 15.7227 11.5673C15.9375 12.1687 15.8122 12.6055 15.7227 12.7487L13.4134 17.6357H14.219C14.7775 17.6787 15.0962 17.1166 15.1857 16.8302C15.7406 15.6666 16.8827 13.2643 17.0116 12.9636C17.1834 12.6628 17.298 12.7666 17.3338 12.8561V15.1654C17.3768 15.638 17.7813 15.7919 17.9782 15.8098H22.1671C21.9523 15.0365 21.5406 14.7 21.3615 14.6284H18.569C18.5869 14.4135 18.6119 13.7584 18.569 12.8561C18.526 11.9539 18.2288 11.2271 18.0856 10.9765L17.4412 9.31172H18.6227C18.8196 9.66974 19.2779 10.4825 19.5356 10.8691C19.8427 11.245 20.1112 11.1376 20.3412 10.9765C20.599 10.7617 20.4128 10.3142 20.2875 10.1173C20.1264 9.83086 19.7504 9.16135 19.5356 8.77469C19.2349 8.34506 18.8733 8.27346 18.7301 8.29136H15.2931C14.9923 8.29136 14.7405 8.77223 14.5949 8.9895L13.5209 10.4395Z" fill="#292D32"/>
  </svg>`;
};

export const evacuationNeighbor = () => {
  return `<svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M25.6668 8.16668V19.8333C25.6668 23.3333 23.7224 25.6667 19.1853 25.6667H8.81498C4.27794 25.6667 2.3335 23.3333 2.3335 19.8333V8.16668C2.3335 4.66668 4.27794 2.33334 8.81498 2.33334H19.1853C23.7224 2.33334 25.6668 4.66668 25.6668 8.16668Z" fill="white" stroke="#C55A11" stroke-width="1.75" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M7 21.1H11.8L10.15 19.45V8.1H17.85V16C18.85 16.04 19.2667 17.1833 19.35 17.75H17.85V19.45L19.5 21.1H21V7H7V21.1Z" fill="#292D32"/>
  <path d="M13 21.1L11.35 19.45H11.8C12.44 19.45 12.8667 19.6167 13 19.7L14.4 21.1H13Z" fill="#292D32"/>
  <circle cx="12.735" cy="9.63769" r="1.03772" fill="#292D32"/>
  <path d="M11.2609 12.697H9.54125C9.4319 12.7426 9.24083 12.8382 9.20687 13.0792C9.15705 13.4329 9.39794 13.5569 9.54125 13.6046H11.2609C11.6812 13.6046 11.9137 13.3817 11.9774 13.2703L12.4073 12.697C12.6009 12.4389 12.6939 12.4423 12.7417 12.5537C12.8213 12.713 13.0283 13.1652 13.2194 13.7002C13.4104 14.2352 13.299 14.6237 13.2194 14.7511L11.1653 19.0979H11.8819C12.3786 19.1361 12.6621 18.6362 12.7417 18.3814C13.2353 17.3464 14.2511 15.2096 14.3658 14.9421C14.5186 14.6746 14.6205 14.767 14.6524 14.8466V16.9006C14.6906 17.321 15.0505 17.4579 15.2256 17.4738H18.9515C18.7604 16.786 18.3942 16.4866 18.235 16.4229H15.751C15.767 16.2319 15.7893 15.6491 15.751 14.8466C15.7128 14.0441 15.4485 13.3976 15.3211 13.1747L14.7479 11.6939H15.7988C15.974 12.0124 16.3816 12.7353 16.6109 13.0792C16.884 13.4136 17.1228 13.318 17.3274 13.1747C17.5567 12.9837 17.3911 12.5856 17.2796 12.4104C17.1363 12.1557 16.8019 11.5602 16.6109 11.2162C16.3434 10.8341 16.0217 10.7704 15.8943 10.7863H12.8372C12.5697 10.7863 12.3457 11.2141 12.2162 11.4073L11.2609 12.697Z" fill="#292D32"/>
  </svg>
`;
};

export const evacuationPetFriendly = () => {
  return `<svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M25.6668 8.16668V19.8333C25.6668 23.3333 23.7224 25.6667 19.1853 25.6667H8.81498C4.27794 25.6667 2.3335 23.3333 2.3335 19.8333V8.16668C2.3335 4.66668 4.27794 2.33334 8.81498 2.33334H19.1853C23.7224 2.33334 25.6668 4.66668 25.6668 8.16668Z" fill="white" stroke="#A9D18E" stroke-width="1.75" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M7 21.1H11.8L10.15 19.45V8.1H17.85V16C18.85 16.04 19.2667 17.1833 19.35 17.75H17.85V19.45L19.5 21.1H21V7H7V21.1Z" fill="#292D32"/>
  <path d="M13 21.1L11.35 19.45H11.8C12.44 19.45 12.8667 19.6167 13 19.7L14.4 21.1H13Z" fill="#292D32"/>
  <circle cx="12.735" cy="9.63769" r="1.03772" fill="#292D32"/>
  <path d="M11.2609 12.697H9.54125C9.4319 12.7426 9.24083 12.8382 9.20687 13.0792C9.15705 13.4329 9.39794 13.5569 9.54125 13.6046H11.2609C11.6812 13.6046 11.9137 13.3817 11.9774 13.2703L12.4073 12.697C12.6009 12.4389 12.6939 12.4423 12.7417 12.5537C12.8213 12.713 13.0283 13.1652 13.2194 13.7002C13.4104 14.2352 13.299 14.6237 13.2194 14.7511L11.1653 19.0979H11.8819C12.3786 19.1361 12.6621 18.6362 12.7417 18.3814C13.2353 17.3464 14.2511 15.2096 14.3658 14.9421C14.5186 14.6746 14.6205 14.767 14.6524 14.8466V16.9006C14.6906 17.321 15.0505 17.4579 15.2256 17.4738H18.9515C18.7604 16.786 18.3942 16.4866 18.235 16.4229H15.751C15.767 16.2319 15.7893 15.6491 15.751 14.8466C15.7128 14.0441 15.4485 13.3976 15.3211 13.1747L14.7479 11.6939H15.7988C15.974 12.0124 16.3816 12.7353 16.6109 13.0792C16.884 13.4136 17.1228 13.318 17.3274 13.1747C17.5567 12.9837 17.3911 12.5856 17.2796 12.4104C17.1363 12.1557 16.8019 11.5602 16.6109 11.2162C16.3434 10.8341 16.0217 10.7704 15.8943 10.7863H12.8372C12.5697 10.7863 12.3457 11.2141 12.2162 11.4073L11.2609 12.697Z" fill="#292D32"/>
  </svg>`;
};

export const evacuationWelfare = () => {
  return `<svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M25.6668 8.16668V19.8333C25.6668 23.3333 23.7224 25.6667 19.1853 25.6667H8.81498C4.27794 25.6667 2.3335 23.3333 2.3335 19.8333V8.16668C2.3335 4.66668 4.27794 2.33334 8.81498 2.33334H19.1853C23.7224 2.33334 25.6668 4.66668 25.6668 8.16668Z" fill="white" stroke="#2E75B6" stroke-width="1.75" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M7 21.1H11.8L10.15 19.45V8.1H17.85V16C18.85 16.04 19.2667 17.1833 19.35 17.75H17.85V19.45L19.5 21.1H21V7H7V21.1Z" fill="#292D32"/>
  <path d="M13 21.1L11.35 19.45H11.8C12.44 19.45 12.8667 19.6167 13 19.7L14.4 21.1H13Z" fill="#292D32"/>
  <circle cx="12.735" cy="9.63769" r="1.03772" fill="#292D32"/>
  <path d="M11.2609 12.697H9.54125C9.4319 12.7426 9.24083 12.8382 9.20687 13.0792C9.15705 13.4329 9.39794 13.5569 9.54125 13.6046H11.2609C11.6812 13.6046 11.9137 13.3817 11.9774 13.2703L12.4073 12.697C12.6009 12.4389 12.6939 12.4423 12.7417 12.5537C12.8213 12.713 13.0283 13.1652 13.2194 13.7002C13.4104 14.2352 13.299 14.6237 13.2194 14.7511L11.1653 19.0979H11.8819C12.3786 19.1361 12.6621 18.6362 12.7417 18.3814C13.2353 17.3464 14.2511 15.2096 14.3658 14.9421C14.5186 14.6746 14.6205 14.767 14.6524 14.8466V16.9006C14.6906 17.321 15.0505 17.4579 15.2256 17.4738H18.9515C18.7604 16.786 18.3942 16.4866 18.235 16.4229H15.751C15.767 16.2319 15.7893 15.6491 15.751 14.8466C15.7128 14.0441 15.4485 13.3976 15.3211 13.1747L14.7479 11.6939H15.7988C15.974 12.0124 16.3816 12.7353 16.6109 13.0792C16.884 13.4136 17.1228 13.318 17.3274 13.1747C17.5567 12.9837 17.3911 12.5856 17.2796 12.4104C17.1363 12.1557 16.8019 11.5602 16.6109 11.2162C16.3434 10.8341 16.0217 10.7704 15.8943 10.7863H12.8372C12.5697 10.7863 12.3457 11.2141 12.2162 11.4073L11.2609 12.697Z" fill="#292D32"/>
  </svg>`;
};

export const getSelectedShelterBg = (color) => {
  return `<svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle opacity="0.3" cx="32" cy="32" r="32" fill="${color}"/>
<circle opacity="0.2" cx="32.0002" cy="32" r="24.8889" fill="${color}"/>
</svg>`;
};
