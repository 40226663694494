import React, { useEffect, useState } from "react";
// Internal imports
import styles from "./hazard-info.module.scss";
import jpText from "../../../utils/locales/jp.json";
import SectionLayout from "../../common/section-layout/SectionLayout";
import SectionTitle from "../../common/left-pane-sec-title/SectionTitle";
import hazardIcon from "../../../assets/icons/hazard-icon.svg";
import DropdownRadioSelect from "../../common/radio-button/DropdownRadioSelect";
import { useDispatch, useSelector } from "react-redux";
import NoDataComponent from "../../common/no-data-message/NoDataComponent";
import noDataFoundImg from "../../../assets/images/no-data-found.svg";
import LegendTable from "../../common/table/LegendTable";
import {
  defaultColorsHazard1,
  defaultColorsHazard2,
} from "../../../utils/appData";
import LegendIcon from "../../../assets/icons/legend-icon.svg";
import {
  saveHazardInfoSelection,
  saveSelectedHazardInfo,
} from "../../../redux/slices/hazardInfromationSlice";
import { hazardInfo } from "../../../utils/appData";
import { legendItems1, legendItems2 } from "../../../utils/appData";

export default function HazardInfo() {
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.commonMapData);
  const selectedHazardInfo = useSelector(
    (state) => state.hazardInformation.selectedHazardInfo
  );
  const [noDataMessage, setNoDataMessage] = useState("");

  const handleRadioSelect = (selected) => {
    dispatch(saveSelectedHazardInfo({ selectedHazardInfo: selected }));
  };
  // useEffect(() => {
  //   if (!selectedHazardInfo.isChecked) {
  //     dispatch(saveHazardInfoSelection({ isHazardInfoSelected: false }));
  //     setNoDataMessage(jpText.SELECT_HAZARD_INFO_TEXT);
  //   } else {
  //     dispatch(saveHazardInfoSelection({ isHazardInfoSelected: true }));
  //   }
  // }, [dispatch, selectedHazardInfo.isChecked, selectedHazardInfo.index]);

  return (
    <div className={styles.container}>
      <SectionLayout>
        <SectionTitle
          title={jpText.HAZARD_INFORMATION}
          icon={hazardIcon}
          borderBottom={true}
        />
        <div className={styles.typeContainer}>
          <DropdownRadioSelect
            items={hazardInfo}
            selectedItem={selectedHazardInfo}
            onRadioSelect={handleRadioSelect}
            isDisabled={loading}
            isSelected={selectedHazardInfo.isChecked}
          />
        </div>
      </SectionLayout>
      <SectionLayout>
        {/* {selectedHazardInfo.isChecked ? (
          <div>
            <SectionTitle
              title={jpText.LEGEND}
              icon={LegendIcon}
              borderBottom={true}
            />
            <div className={styles.legendTable}>
              {selectedHazardInfo.index === 0 || selectedHazardInfo.index === 1 ? (
                <LegendTable
                  legendItems={legendItems1}
                  colorCodes={defaultColorsHazard1}
                />
              ) : (
                <LegendTable
                  legendItems={legendItems2}
                  colorCodes={defaultColorsHazard2}
                />
              )}
            </div>
          </div>
        ) : (
          <NoDataComponent
            image={noDataFoundImg}
            message={noDataMessage}
            className={styles.noDataMsgSec}
          />
        )} */}

        <NoDataComponent
          image={noDataFoundImg}
          message="詳細を表示するには危険情報を選択してください。"
          className={styles.noDataMsgSec}
        />
      </SectionLayout>
    </div>
  );
}
