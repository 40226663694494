export const removePrevListOfMarkers = async (map) => {
  if (map?.mrk_list_wg?.length > 0) {
    map.mrk_list_wg.forEach((item) => {
      map.removeWidget(item);
    });
  }
};
export const removePrevSelectedMarkerBg = (map) => {
  if (map?.marker_selected_bg) {
    map.removeWidget(map.marker_selected_bg);
    map.marker_selected_bg = null;
  }
};
export const removePrevListOfShelter = async (map) => {
  if (map?.shelter_list_wg?.length > 0) {
    map.shelter_list_wg.forEach((item) => {
      map.removeWidget(item);
    });
  }
};
export const removePrevSelectedShelterBg = (map) => {
  if (map?.shelter_selected_bg) {
    map.removeWidget(map.shelter_selected_bg);
    map.shelter_selected_bg = null;
  }
};
export const removePrevListOfShelterCircle = async (map) => {
  if (map?.shelter_circle_wg?.length > 0) {
    map.shelter_circle_wg.forEach((item) => {
      map.removeWidget(item);
    });
  }
};
