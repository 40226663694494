export const navHomeIcon = (color) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
    >
      <path
        d="M18 27V22.5"
        stroke={color ? color : "white"}
        strokeWidth="2.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.1049 4.23002L4.70991 12.555C3.53991 13.485 2.78991 15.45 3.04491 16.92L5.03991 28.86C5.39991 30.99 7.43991 32.715 9.59991 32.715H26.3999C28.5449 32.715 30.5999 30.975 30.9599 28.86L32.9549 16.92C33.1949 15.45 32.4449 13.485 31.2899 12.555L20.8949 4.24502C19.2899 2.95502 16.6949 2.95502 15.1049 4.23002Z"
        stroke={color ? color : "white"}
        strokeWidth="2.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const navChargingStationIcon = (color) => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_4153_473)">
        <path
          d="M30.709 7.88908H30.6528V6.1383C30.6528 5.70145 30.2986 5.34728 29.8618 5.34728C29.4249 5.34728 29.0707 5.70145 29.0707 6.1383V7.88908H27.2636V6.1383C27.2636 5.70145 26.9095 5.34728 26.4726 5.34728C26.0358 5.34728 25.6816 5.70145 25.6816 6.1383V7.88908H25.6254C25.1885 7.88908 24.8344 8.24324 24.8344 8.6801V11.2219C24.8344 12.787 25.9192 14.1029 27.3762 14.459V25.6254C27.3762 26.1236 26.9709 26.5289 26.4726 26.5289C25.9744 26.5289 25.5691 26.1236 25.5691 25.6254V18C25.5691 15.9656 24.1079 14.2669 22.18 13.896V7.40914C22.18 5.80507 20.875 4.5 19.2709 4.5H8.25638C6.65225 4.5 5.34723 5.80507 5.34723 7.40914V29.918H5.29102C4.85416 29.918 4.5 30.2721 4.5 30.709C4.5 31.1458 4.85416 31.5 5.29102 31.5H22.2363C22.6732 31.5 23.0273 31.1458 23.0273 30.709C23.0273 30.2721 22.6732 29.918 22.2363 29.918H22.18V15.5255C23.2269 15.8609 23.9871 16.8432 23.9871 18V25.6254C23.9871 26.996 25.1021 28.111 26.4726 28.111C27.8432 28.111 28.9583 26.996 28.9583 25.6254V14.459C30.4153 14.1029 31.5 12.787 31.5 11.2219V8.6801C31.5 8.24319 31.1458 7.88908 30.709 7.88908ZM6.92926 29.918V7.40914C6.92926 6.6774 7.52458 6.08203 8.25638 6.08203H19.2709C20.0027 6.08203 20.598 6.6774 20.598 7.40914V29.918H6.92926ZM29.918 11.2219C29.918 12.1872 29.1325 12.9726 28.1672 12.9726C27.2018 12.9726 26.4164 12.1872 26.4164 11.2219V9.47111H29.918V11.2219Z"
          fill={color ? color : "white"}
        />
        <path
          d="M18 7.88916H9.52734C9.09049 7.88916 8.73633 8.24332 8.73633 8.68018V15.4583C8.73633 15.8951 9.09049 16.2493 9.52734 16.2493H18C18.4368 16.2493 18.791 15.8951 18.791 15.4583V8.68018C18.791 8.24327 18.4368 7.88916 18 7.88916ZM17.2089 14.6673H10.3184V9.47119H17.2089V14.6673ZM15.5641 22.2927H13.2431L14.4711 19.8366C14.6665 19.4458 14.5081 18.9707 14.1173 18.7753C13.7268 18.58 13.2515 18.7383 13.0561 19.129L11.2557 22.7299C11.1954 22.8505 11.1669 22.9845 11.173 23.1192C11.1791 23.2539 11.2195 23.3848 11.2903 23.4995C11.3612 23.6142 11.4603 23.7088 11.578 23.7745C11.6958 23.8402 11.8284 23.8746 11.9632 23.8746H14.2842L13.0561 26.3307C12.8607 26.7215 13.0191 27.1966 13.4099 27.392C13.5194 27.447 13.6404 27.4757 13.763 27.4757C14.0531 27.4757 14.3325 27.3154 14.4711 27.0383L16.2716 23.4374C16.3319 23.3168 16.3604 23.1828 16.3543 23.0481C16.3482 22.9134 16.3078 22.7825 16.237 22.6678C16.1661 22.5531 16.0671 22.4585 15.9493 22.3928C15.8315 22.3271 15.6989 22.2927 15.5641 22.2927Z"
          fill={color ? color : "white"}
        />
      </g>
      <defs>
        <clipPath id="clip0_4153_473">
          <rect width="36" height="36" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const navShelterIcon = (color) => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M33 10.5V25.5C33 30 30.5 33 24.6667 33H11.3333C5.5 33 3 30 3 25.5V10.5C3 6 5.5 3 11.3333 3H24.6667C30.5 3 33 6 33 10.5Z"
        stroke={color ? color : "white"}
        strokeWidth="2.25"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.1309 27.3665C20.8976 27.3665 24.7618 25.5735 24.7618 23.3618C24.7618 21.9636 23.2175 20.7328 20.8772 20.0164C20.8414 20.0924 20.7978 20.1859 20.7479 20.2925C20.4943 20.8356 20.0805 21.7218 19.7214 22.3951C19.3347 23.1685 18.4555 23.2697 18.0642 23.2237H16.338L18.0504 19.4565C17.4331 19.3914 16.7906 19.3571 16.1309 19.3571C11.3642 19.3571 7.5 21.15 7.5 23.3618C7.5 25.5735 11.3642 27.3665 16.1309 27.3665Z"
        fill={color ? color : "white"}
      />
      <circle cx="19.5142" cy="9" r="1.5" fill={color ? color : "white"} />
      <path
        d="M17.3835 13.4222H14.8978C14.7397 13.488 14.4635 13.6261 14.4144 13.9745C14.3424 14.4858 14.6906 14.665 14.8978 14.7341H17.3835C17.9911 14.7341 18.3271 14.4118 18.4192 14.2507L19.0406 13.4222C19.3204 13.049 19.4549 13.0539 19.5239 13.215C19.639 13.4452 19.9382 14.0988 20.2144 14.8722C20.4906 15.6455 20.3295 16.2071 20.2144 16.3912L17.2454 22.6745H18.2811C18.9992 22.7297 19.4089 22.007 19.5239 21.6388C20.2374 20.1428 21.7058 17.054 21.8715 16.6674C22.0925 16.2807 22.2398 16.4142 22.2858 16.5293V19.4983C22.3411 20.1059 22.8612 20.3039 23.1144 20.3269H28.5001C28.2239 19.3326 27.6945 18.8999 27.4644 18.8078H23.8739C23.8969 18.5317 23.9291 17.6893 23.8739 16.5293C23.8187 15.3693 23.4366 14.4349 23.2525 14.1126L22.4239 11.9722H23.943C24.1961 12.4325 24.7853 13.4774 25.1168 13.9745C25.5116 14.4579 25.8568 14.3198 26.1525 14.1126C26.4839 13.8364 26.2445 13.261 26.0834 13.0079C25.8763 12.6396 25.3929 11.7788 25.1168 11.2817C24.7301 10.7293 24.2652 10.6373 24.081 10.6603H19.662C19.2754 10.6603 18.9516 11.2785 18.7644 11.5579L17.3835 13.4222Z"
        fill={color ? color : "white"}
      />
    </svg>
  );
};

export const navHazardIcon = (color) => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="18"
        cy="18"
        r="15.75"
        fill="none"
        stroke={color ? color : "white"}
        strokeWidth="2.25"
      />
      <path
        d="M12.6887 30.4898C13.4024 31.4455 15.6672 32.4909 18.0002 33C18.5951 33.3197 16.5926 33.3615 17.2437 33.5251C13.7769 33.0135 11.8162 32.8215 9.37262 31.5657C6.35283 29.6909 5.59184 28.4472 4.5203 27.0587C3.17376 25.282 2.40968 22.7953 2.19596 21.7741C4.14063 16.7602 8.8405 13.1833 14.4054 13.0046C17.0869 12.912 19.7358 13.6149 22.0185 15.0248C24.3013 16.4347 26.1157 18.4886 27.2334 20.9276C27.2909 21.0528 27.2989 21.1952 27.2557 21.326C27.2125 21.4568 27.1213 21.5664 27.0005 21.6326C26.8797 21.6989 26.7383 21.7169 26.6047 21.6831C26.4712 21.6493 26.3554 21.5661 26.2808 21.4503C22.1411 14.9285 13.1571 16.8585 11.385 23.2848C11.0343 24.5052 10.9695 25.79 11.1956 27.0395C11.4216 28.289 11.9326 29.4696 12.6887 30.4898Z"
        fill={color ? color : "white"}
      />
    </svg>
  );
};

export const navFacilityIcon = (color) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
    >
      <path
        d="M3 33H33"
        stroke={color ? color : "white"}
        strokeWidth="2.25"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.4248 32.9999L4.49981 14.9549C4.49981 14.0399 4.9348 13.1699 5.6548 12.5999L16.1548 4.42492C17.2348 3.58492 18.7498 3.58492 19.8448 4.42492L30.3448 12.5849C31.0798 13.1549 31.4998 14.0249 31.4998 14.9549V32.9999"
        stroke={color ? color : "white"}
        strokeWidth="2.25"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M23.25 16.5H12.75C11.505 16.5 10.5 17.505 10.5 18.75V33H25.5V18.75C25.5 17.505 24.495 16.5 23.25 16.5Z"
        stroke={color ? color : "white"}
        strokeWidth="2.25"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15 24.375V26.625"
        stroke={color ? color : "white"}
        strokeWidth="2.25"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.75 11.25H20.25"
        stroke={color ? color : "white"}
        strokeWidth="2.25"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const navSettingIcon = (color) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
    >
      <path
        d="M18 22.5C20.4853 22.5 22.5 20.4853 22.5 18C22.5 15.5147 20.4853 13.5 18 13.5C15.5147 13.5 13.5 15.5147 13.5 18C13.5 20.4853 15.5147 22.5 18 22.5Z"
        stroke={color ? color : "white"}
        strokeWidth="2.25"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3 19.3199V16.6799C3 15.1199 4.275 13.8299 5.85 13.8299C8.565 13.8299 9.675 11.9099 8.31 9.5549C7.53 8.2049 7.995 6.4499 9.36 5.6699L11.955 4.1849C13.14 3.4799 14.67 3.8999 15.375 5.0849L15.54 5.3699C16.89 7.7249 19.11 7.7249 20.475 5.3699L20.64 5.0849C21.345 3.8999 22.875 3.4799 24.06 4.1849L26.655 5.6699C28.02 6.4499 28.485 8.2049 27.705 9.5549C26.34 11.9099 27.45 13.8299 30.165 13.8299C31.725 13.8299 33.015 15.1049 33.015 16.6799V19.3199C33.015 20.8799 31.74 22.1699 30.165 22.1699C27.45 22.1699 26.34 24.0899 27.705 26.4449C28.485 27.8099 28.02 29.5499 26.655 30.3299L24.06 31.8149C22.875 32.5199 21.345 32.0999 20.64 30.9149L20.475 30.6299C19.125 28.2749 16.905 28.2749 15.54 30.6299L15.375 30.9149C14.67 32.0999 13.14 32.5199 11.955 31.8149L9.36 30.3299C7.995 29.5499 7.53 27.7949 8.31 26.4449C9.675 24.0899 8.565 22.1699 5.85 22.1699C4.275 22.1699 3 20.8799 3 19.3199Z"
        stroke={color ? color : "white"}
        strokeWidth="2.25"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const resetIcon = (color) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="vuesax/linear/rotate-left">
        <g id="rotate-left">
          <path
            id="Vector"
            d="M7.59157 4.23333C8.31657 4.01667 9.11657 3.875 9.9999 3.875C13.9916 3.875 17.2249 7.10833 17.2249 11.1C17.2249 15.0917 13.9916 18.325 9.9999 18.325C6.00824 18.325 2.7749 15.0917 2.7749 11.1C2.7749 9.61667 3.2249 8.23333 3.99157 7.08333"
            stroke={color ? color : "#0E9790"}
            strokeWidth="1.2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Vector_2"
            d="M6.55811 4.43333L8.96644 1.66667"
            stroke={color ? color : "#0E9790"}
            strokeWidth="1.2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Vector_3"
            d="M6.55811 4.43333L9.36644 6.48333"
            stroke={color ? color : "#0E9790"}
            strokeWidth="1.2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
    </svg>
  );
};

export const closeCircleOutline = (color) => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 22.5C17.5 22.5 22 18 22 12.5C22 7 17.5 2.5 12 2.5C6.5 2.5 2 7 2 12.5C2 18 6.5 22.5 12 22.5Z"
        stroke={color || "#727C8D"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.16992 15.3299L14.8299 9.66992"
        stroke={color || "#727C8D"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.8299 15.3299L9.16992 9.66992"
        stroke={color || "#727C8D"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const locationMarker = (color) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.99996 2.5C7.63254 2.5 4.64282 4.44869 4.64282 6.86248C4.64282 11.0361 9.99996 16.4792 9.99996 16.4792C9.99996 16.4792 15.3571 11.0353 15.3571 6.86248C15.3571 4.44957 12.3674 2.5 9.99996 2.5ZM9.99996 9.81199C9.38618 9.81199 8.79753 9.56329 8.36352 9.12062C7.92951 8.67795 7.68568 8.07756 7.68568 7.45152C7.68568 6.82549 7.92951 6.2251 8.36352 5.78243C8.79753 5.33975 9.38618 5.09106 9.99996 5.09106C10.6138 5.09106 11.2024 5.33975 11.6364 5.78243C12.0704 6.2251 12.3143 6.82549 12.3143 7.45152C12.3143 8.07756 12.0704 8.67795 11.6364 9.12062C11.2024 9.56329 10.6138 9.81199 9.99996 9.81199Z"
        fill={color ? color : "#0DAC8B"}
      />
      <ellipse
        opacity="0.5"
        cx="9.99979"
        cy="16.4286"
        rx="2.14286"
        ry="1.07143"
        fill={color ? color : "#0DAC8B"}
      />
    </svg>
  );
};

export const shelterIcons = (icon) => {
  return <img src={icon} alt={icon} />;
};
