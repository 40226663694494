import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// Internal Imports
import Map from "./Map";
import styles from "../assets/scss/right-pane.module.scss";
import slidingArrow from "../assets/icons/sliding-arrow.svg";
import { saveSideBarWidth } from "../redux/slices/commonMapDataSlice";

export default function RightPane(props) {
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.commonMapData);
  const { chargingStationLoading } = useSelector(
    (state) => state.chargingStationData
  );
  const { shelterLoading } = useSelector((state) => state.shelterData);

  const { selectedMenu, children } = props;
  const [sideBarWidth, setSideBarWidth] = useState(343);
  const [isResizing, setIsResizing] = useState(false);
  const sidebarRef = useRef(null);
  const initialMousePosition = useRef(0);
  const initialSidebarWidth = useRef(0);

  const handleMouseDown = (e) => {
    e.preventDefault();
    setIsResizing(true);

    initialMousePosition.current = e.clientX; // Capture initial mouse position
    initialSidebarWidth.current = sideBarWidth; // Capture current sidebar width
  };
  const handleMouseMove = (e) => {
    if (isResizing) {
      const mouseDelta = e.clientX - initialMousePosition.current; // Calculate how far the mouse has moved
      const newWidth = initialSidebarWidth.current + mouseDelta; // Adjust sidebar width based on mouse movement

      if (newWidth >= 343 && newWidth <= 900) {
        setSideBarWidth(newWidth);
      }
    }
  };
  const handleMouseUp = () => {
    setIsResizing(false);
    dispatch(saveSideBarWidth({ sideBarWidth: sideBarWidth })); 
  };

  // TO BE COMMENTED
  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     dispatch(saveSideBarWidth({ sideBarWidth: sideBarWidth }));
  //   }, 1000);
  //   return () => clearTimeout(timer);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [sideBarWidth]);

  useEffect(() => {
    if (isResizing) {
      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mouseup", handleMouseUp);
    } else {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    }

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isResizing]);

  return (
    <div className={styles.container}>
      {children && (
        <div
          ref={sidebarRef}
          style={{ width: `${sideBarWidth}px` }}
          className={`${styles.sidebarSection} ${
            (loading || shelterLoading || chargingStationLoading) && "noClick"
          }`}
        >
          <div className={styles.sidebarContainer}>{children}</div>
          <div
            className={`${styles.slidingBtn} ${
              (loading || shelterLoading || chargingStationLoading) &&
              styles.slidingBtnDisabled
            }`}
            onMouseDown={handleMouseDown}
          >
            <img src={slidingArrow} alt="icon" onMouseDown={handleMouseDown} />
          </div>
        </div>
      )}

      <div className={styles.mapContainer}>
        <Map selectedMenu={selectedMenu} />
      </div>
    </div>
  );
}
