import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  shelterList: [],
  isCircleToggled: false,
  selectedShelterTypes: [],
  shelterDetails: null,
  shelterInfo: null,
  shelterSurroundingInfo: null,
  noDataMessage: "",
  shelterCircleRadius: 1000, // In meter
  selectedRadius: "1km",
  shelterLoading: false,
  isShelterIconClicked: false,
};

export const shelterVisualizationSlice = createSlice({
  name: "shelterData",
  initialState,
  reducers: {
    saveShelterList: (state, action) => {
      const { shelterList } = action.payload;
      return {
        ...state,
        shelterList: shelterList,
      };
    },
    saveIsCircleToggled: (state, action) => {
      const { isCircleToggled } = action.payload;
      return {
        ...state,
        isCircleToggled: isCircleToggled,
      };
    },
    saveSelectedShelterTypes: (state, action) => {
      const { selectedShelterTypes } = action.payload;
      return {
        ...state,
        selectedShelterTypes: selectedShelterTypes,
      };
    },
    saveClickedShelterDetails: (state, action) => {
      const { shelterDetails } = action.payload;
      return {
        ...state,
        shelterDetails: shelterDetails,
      };
    },
    saveShelterInfo: (state, action) => {
      const { shelterInfo } = action.payload;
      return {
        ...state,
        shelterInfo: shelterInfo,
      };
    },
    saveShelterSurroundingInfo: (state, action) => {
      const { shelterSurroundingInfo } = action.payload;
      return {
        ...state,
        shelterSurroundingInfo: shelterSurroundingInfo,
      };
    },
    saveShelterNoDataMessage: (state, action) => {
      const { noDataMessage } = action.payload;
      return {
        ...state,
        noDataMessage: noDataMessage,
      };
    },
    saveShelterCircleRadius: (state, action) => {
      const { shelterCircleRadius } = action.payload;
      return {
        ...state,
        shelterCircleRadius: shelterCircleRadius,
      };
    },
    saveShelterLoading: (state, action) => {
      const { shelterLoading } = action.payload;
      return {
        ...state,
        shelterLoading: shelterLoading,
      };
    },
    saveSelectedRadius: (state, action) => {
      const { selectedRadius } = action.payload;
      return { ...state, selectedRadius };
    },
    saveIsShelterIconClicked: (state, action) => {
      const { isShelterIconClicked } = action.payload;
      return { ...state, isShelterIconClicked };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  saveShelterList,
  saveIsCircleToggled,
  saveSelectedShelterTypes,
  saveClickedShelterDetails,
  saveShelterInfo,
  saveShelterSurroundingInfo,
  saveShelterNoDataMessage,
  saveShelterCircleRadius,
  saveShelterLoading,
  saveSelectedRadius,
  saveIsShelterIconClicked,
} = shelterVisualizationSlice.actions;

export default shelterVisualizationSlice.reducer;
