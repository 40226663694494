import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isHazardInfoSelected: false,
  selectedHazardInfo: {},
};

export const hazardInformationSlice = createSlice({
  name: "hazardInformation",
  initialState,
  reducers: {
    saveHazardInfoSelection: (state, action) => {
      const { isHazardInfoSelected } = action.payload;
      return {
        ...state,
        isHazardInfoSelected: isHazardInfoSelected,
      };
    },
    saveSelectedHazardInfo: (state, action) => {
      const { selectedHazardInfo } = action.payload;
      return {
        ...state,
        selectedHazardInfo: selectedHazardInfo,
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const { saveHazardInfoSelection, saveSelectedHazardInfo } =
  hazardInformationSlice.actions;

export default hazardInformationSlice.reducer;
