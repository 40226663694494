import { useDispatch } from "react-redux";
import {
  removePrevListOfMarkers,
  removePrevSelectedMarkerBg,
} from "./mapWidgetFunctions";
import { filterMarkersInsideLatLngBounds } from "../utils/filterMarkersInsideLatLngBounds";
import {
  getCustomMarker,
  getCustomMarkerSelectedBg,
} from "../utils/getCustomMarker";
import {
  saveChargingStationLoading,
  saveClickedMarkerData,
} from "../redux/slices/chargingStationSlice";

export default function useDrawStationMarkers() {
  const dispatch = useDispatch();

  const drawStationMarkers = async (
    map,
    latLngBounds,
    stationList,
    chargingTypeColors
  ) => {
    await removePrevListOfMarkers(map);
    let tempWgList = [],
      isMarkerSelected = false;
    if (stationList?.length > 0 && latLngBounds) {
      const listInsideViewPort = filterMarkersInsideLatLngBounds(
        latLngBounds,
        stationList
      );

      listInsideViewPort.forEach((listItem, coordIndex) => {
        const chargeTypes = listItem.charge_types.split(";");
        let colorCode = "#FF0000";

        if (chargeTypes?.length > 1) {
          colorCode = "#808080";
        } else {
          chargingTypeColors.forEach((colorItem) => {
            if (colorItem.key.includes(chargeTypes[0])) {
              colorCode = colorItem.colorCode;
            }
          });
        }

        let imgData = getCustomMarker(colorCode);

        let latLngs = new window.ZDC.LatLng(
          listItem.position[1],
          listItem.position[0]
        );

        // Marker count HTML source generation
        let countValue = null;
        if (chargeTypes?.length > 1) {
          let customCountStyle = `left: 0.5px; width: 15px;`;
          if (chargeTypes?.length >= 10 && chargeTypes?.length < 100) {
            customCountStyle = `left: -2px; width: 20px;`;
          } else if (chargeTypes?.length >= 100) {
            customCountStyle = `left: -5px; width: 26px;`;
          }
          let customStyle = `font-size: 10px; color: white; background: #808080; position: absolute; top:-16px; ${customCountStyle} text-align: center; border-radius: 2px; padding: 2px`;
          countValue = `<div style="${customStyle}"><div>
                ${chargeTypes?.length}
                </div></div>`;
        }

        //  Draw marker with And/Or marker count
        let marker_wg = new window.ZDC.UserWidget(latLngs, {
          htmlSource: countValue ? `${imgData}${countValue}` : imgData,
          offset: new window.ZDC.Point(-10, -14),
          propagation: true,
        });

        tempWgList.push(marker_wg);
        map.addWidget(marker_wg);

        const markerWidgetDiv = marker_wg._element;
        if (markerWidgetDiv) {
          markerWidgetDiv.style.height = "28px";
          markerWidgetDiv.style.zIndex = "7";
        }

        // Updated selected marker background if color is changed
        if (
          map?.marker_selected_bg &&
          map?.marker_selected_bg?._latlng?.lat === listItem.position[1] &&
          map?.marker_selected_bg?._latlng?.lng === listItem.position[0]
        ) {
          isMarkerSelected = true;
          drawSelectedMarkerBackground(
            map,
            colorCode,
            latLngs,
            listInsideViewPort[coordIndex]
          );
        }
        // Set marker details to redux for the clicked marker
        marker_wg.addEventListener("click", function (event) {
          drawSelectedMarkerBackground(
            map,
            colorCode,
            latLngs,
            listInsideViewPort[coordIndex]
          );
        });
      });

      if (!isMarkerSelected) {
        removePrevSelectedMarkerBg(map);
        dispatch(
          saveClickedMarkerData({
            markerData: null,
          })
        );
      }
      dispatch(saveChargingStationLoading({ chargingStationLoading: false }));
    } else {
      dispatch(saveChargingStationLoading({ chargingStationLoading: false }));
    }

    map.mrk_list_wg = tempWgList;
  };

  const drawSelectedMarkerBackground = (
    map,
    colorCode,
    latLngs,
    markerData
  ) => {
    removePrevSelectedMarkerBg(map);
    const selectMarkerBgImg = getCustomMarkerSelectedBg(colorCode);
    let marker_selected_bg = new window.ZDC.UserWidget(latLngs, {
      htmlSource: selectMarkerBgImg,
      offset: new window.ZDC.Point(-15, -5),
      propagation: true,
    });
    map.marker_selected_bg = marker_selected_bg;
    map.addWidget(marker_selected_bg);

    const markerSelectedBgWidgetDiv = marker_selected_bg._element;
    if (markerSelectedBgWidgetDiv) {
      markerSelectedBgWidgetDiv.style.height = "29px";
      markerSelectedBgWidgetDiv.style.clipPath = "circle(50%)";
      markerSelectedBgWidgetDiv.style.zIndex = "8";
    }

    dispatch(
      saveClickedMarkerData({
        markerData: markerData,
      })
    );
  };

  return { drawStationMarkers };
}
