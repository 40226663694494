import { configureStore } from "@reduxjs/toolkit";
import commonMapDataReducer from "./slices/commonMapDataSlice";
import chargingStationReducer from "./slices/chargingStationSlice";
import shelterVisualizationReducer from "./slices/shelterVisualizationSlice";
import hazardInfromationSlice from "./slices/hazardInfromationSlice";

export const store = () =>
  configureStore({
    reducer: {
      commonMapData: commonMapDataReducer,
      chargingStationData: chargingStationReducer,
      shelterData: shelterVisualizationReducer,
      hazardInformation: hazardInfromationSlice,
    },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
      }),
  });
